import * as R from 'ramda';
import React from 'react';
import { FieldArray } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, lifecycle, pure, withState } from 'react-recompose';
// features
import { getAllAvailableRefTypesByScopeRequest } from '../../reference/actions';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature invoice
import { getReferencesFields, getNewReferenceFields } from '../settings/common-fields';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const enhance = compose(
  withState('expanded', 'setExpanded', true),
  lifecycle({
    componentDidMount() {
      const { scope, getAllAvailableRefTypesByScopeRequest } = this.props;

      getAllAvailableRefTypesByScopeRequest(scope);
    },
  }),
  pure,
);

const Header = (props: Object) => {
  const { push, title, expanded, setExpanded } = props;

  const toggleIconName = G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple');

  return (
    <Flex
      p='5px'
      fontWeight='bold'
      color={darkBlueColor}
      justifyContent='center'
      alignItems='flex-start'
      textTransform='uppercase'
      bg={G.getTheme('colors.whiteGrey')}
    >
      <Box mr={15} ml='auto'>{title}</Box>
      <Box
        cursor='pointer'
        onClick={() => {
          push(getNewReferenceFields());

          if (R.not(expanded)) {
            setExpanded(true);
          }
        }}
      >
        {I.plusRound(darkBlueColor)}
      </Box>
      <Box
        px='6px'
        ml='auto'
        cursor='pointer'
        onClick={() => setExpanded(R.not(expanded))}
      >
        {I[toggleIconName](darkBlueColor)}
      </Box>
    </Flex>
  );
};

const getAllowedValues = ({ values, itemIndex, availableTypes }: Object) => {
  const selectedTypeGuid = R.path([GC.FIELD_REFERENCES, itemIndex, GC.FIELD_REFERENCE_TYPE_GUID], values);
  const selectedType = R.find(R.propEq(selectedTypeGuid, GC.FIELD_GUID), availableTypes);

  return R.pathOr([], ['allowedValues'], selectedType);
};

const getAllowedOptions = (allowedValues: Array) => {
  if (G.isNotNilAndNotEmpty(allowedValues)) {
    return G.addEmptyOptionToDropDown(R.map(
      (item: string) => ({ label: item, value: item }),
      allowedValues,
    ));
  }

  return [];
};


const ItemRow = (props: Object) => {
  const { item, remove, itemIndex, arrayLength, availableTypes } = props;

  const allowedValues = getAllowedValues(props);
  const referenceTypes = G.addEmptyOptionToDropDown(G.mapNameGuidToLabelValue(availableTypes));

  const disabled = R.has(GC.FIELD_VERSION, item);

  return (
    <Flex mt={25}>
      <Flex
        mx={10}
        display='flex'
        cursor='pointer'
        alignItems='center'
        onClick={() => remove(itemIndex)}
        title={G.getWindowLocale('titles:remove', 'Remove')}
      >
        {I.trash(darkBlueColor)}
      </Flex>
      <Fieldset2
        {...G.getFormikProps(props)}
        {...G.getArrayFormikProps(props)}
        fieldsetType='array'
        rowMapIndex={itemIndex}
        arrayLength={arrayLength}
        arrayName={GC.FIELD_REFERENCES}
        referenceTypes={referenceTypes}
        allowedValues={getAllowedOptions(allowedValues)}
        fields={getReferencesFields(allowedValues, disabled)}
      />
    </Flex>
  );
};

const ReferencesArray = (props: Object) => {
  const { values, expanded, setExpanded } = props;

  const items = R.pathOr([], [GC.FIELD_REFERENCES], values);
  const arrayLength = R.length(items);

  return (
    <FieldArray
      name={GC.FIELD_REFERENCES}
      render={(arrayHelpers: Object) => (
        <Box mb={20}>
          <Header
            expanded={expanded}
            setExpanded={setExpanded}
            push={R.path(['push'], arrayHelpers)}
            title={G.getWindowLocale('titles:references', 'References')}
          />
          <Box>
            {
              R.and(expanded, R.gt(arrayLength, 0)) &&
              items.map((item: string, i: number) => (
                <ItemRow {...props} {...arrayHelpers} key={i} item={item} itemIndex={i} arrayLength={arrayLength} />
              ))
            }
          </Box>
        </Box>
      )}
    />
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  availableTypes: makeSelectAvailableReferenceTypesByScope(state),
}));

export default connect(mapStateToProps, {
  getAllAvailableRefTypesByScopeRequest,
})(enhance(ReferencesArray));
