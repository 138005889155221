import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../common/selectors';
// components
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
import { withShowDocuments } from '../../components/async-documents/with-show-documents';
import { Table, PageActions, getConfirmModal, QuickFilterOutsideControl3 } from '../../components';
// features
import PC from '../permission/role-permission';
import { AuthWrapper } from '../permission/index';
import { makeSelectOpenedSidebar } from '../navbar-menu/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { makeSelectAllAvailableCloReferenceTypes } from '../reference/selectors';
import { makeSelectBranchesTree, makeSelectCurrentBranchGuid } from '../branch/selectors';
// forms
import SelectPrintTemplateForm from '../../forms/forms/select-print-template-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
import { withAsyncEditCloInvoice } from '../../hocs/with-async-edit-clo-invoice';
// icons
import * as I from '../../svgs';
// ui
import { Box, IconWrapper, AbsoluteBox, ListWrapper, ZOrderWrapper } from '../../ui';
// utilities
import endpointsMap from '../../utilities/endpoints';
// feature master-invoice
import * as C from './constants';
import { FILTER_PARAMS } from './settings/filter-params';
import UpdateInvoices from './components/update-invoices';
import AddMasterInvoice from './components/add-master-invoice';
import PrintOptionsForm from './components/print-options-form';
import { withHandleAudit, withMasterInvoiceActions } from './hocs';
import SelectedListTotals from './components/selected-list-totals';
import { tableSettings, columnSettings } from './settings/table-settings';
import MasterInvoiceWithCharges from './components/master-invoice-with-charges-new';
import AsyncPrintInvoicesOptionsForm from './components/print-invoices-options-form';
import DocumentFormWithAsyncCloEvents from './components/document-form-with-async-clo-events';
import {
  selectItem,
  setReports,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  addMasterInvoices,
  qbIIFImportRequest,
  getItemListRequest,
  customExportRequest,
  updateReportRequest,
  createReportRequest,
  setTableTitleFilter,
  updateInvoiceRequest,
  printInvoicesRequest,
  setQuickFilterParams,
  printByReportRequest,
  getXMLByReportRequest,
  sendToQuickBookRequest,
  resetListAndPagination,
  printCloInvoiceRequest,
  exportReportDataRequest,
  getMasterInvoiceDetails,
  updateCloInvoiceRequest,
  createFactoringDocument,
  createCloDocumentRequest,
  sendToSageIntacctRequest,
  printMasterInvoiceRequest,
  getMasterInvoiceXMLRequest,
  changeDefaultReportRequest,
  updateMasterInvoiceInvoices,
  deleteMasterInvoicesRequest,
  getFactoringCompanyFileRequest,
  removeInvoiceFromMasterInvoice,
  handleUpdateMasterInvoiceRequest,
  createAndExportFactoringDocument,
  exportCustomerMasterInvoiceToEDIRequest,
} from './actions';
import {
  makeSelectItemList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectFilterParams,
  makeSelectReportStatus,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectDocumentTypeOptions,
  makeSelectMasterInvoiceConfigs,
  makeSelectAvailableCustomExport,
  makeSelectAvailableFactoringTypes,
} from './selectors';
//////////////////////////////////////////////////

const selectInvoiceLocaleTxt = G.getWindowLocale(
  'messages:driver:select-invoice',
  'Please, select an Invoice!',
);

const FilterByCLOReference = ({ handleChangeFilterByCLOReference }: Object) => (
  <Box ml={15} mt='6px'>
    <QuickFilterOutsideControl3
      handleSetFilter={handleChangeFilterByCLOReference}
      placeholder={G.getWindowLocale('titles:search', 'Search')}
      label={`
        ${G.getWindowLocale('titles:search-by', 'Search By')} ${
        G.getWindowLocale('titles:clo-reference', 'CLO Reference')}
      `}
    />
  </Box>
);

const enhance = compose(
  withFixedPopover,
  withAsyncEditCloInvoice({ updateFrom: GC.PAGE_MASTER_INVOICE_REPORT }),
  withShowDocuments,
  withHandleAudit,
  withHandlers({
    handlePrintMasterInvoice: (props: Object) => (masterInvoice: Object) => {
      const {
        configs,
        itemList,
        openModal,
        closeModal,
        configOptions,
        documentTemplates,
        printByReportRequest,
        printMasterInvoiceRequest,
      } = props;

      const masterInvoiceDocumentTemplates = G.getPropFromObject(
        GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE,
        documentTemplates,
      );

      if (G.isNilOrEmpty(masterInvoiceDocumentTemplates)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:no-configured-document-templates', 'There are no configured document templates'),
        );
      }

      let component;
      const masterInvoiceGuid = G.getGuidFromObject(masterInvoice);

      const masterInvoiceGuids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(R.or(itemList, []));

      if (R.and(R.isNil(masterInvoiceGuid), R.isEmpty(masterInvoiceGuids))) {
        component = (
          <SelectPrintTemplateForm
            submitAction={(values: Object) => printByReportRequest(values)}
            printableSection={GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE_REPORT}
          />
        );
      } else {
        const templateOptions = R.map(
          ({ guid, name, format }: Object) => ({ format, value: guid, label: name }),
          masterInvoiceDocumentTemplates,
        );

        const documentTypeOptions = configOptions.map(({ value, displayedValue }: Object) => ({
          value,
          label: displayedValue,
        }));

        const initialValues = {
          [C.DOCUMENT_TYPES]: R.pathOr(null, ['defaultDocumentTypeGuids'], configs),
          [GC.FIELD_TEMPLATE_GUID]: R.pathOr(null, ['defaultMasterInvoiceTemplate'], configs),
        };

        const submitAction = (values: Object) => {
          let data = values;

          if (G.isNotNil(masterInvoiceGuid)) {
            data = R.assoc(GC.FIELD_INVOICE_MASTER_INVOICE_GUID, masterInvoiceGuid, data);
          } else {
            data = R.assoc(GC.FIELD_MASTER_INVOICE_GUIDS, masterInvoiceGuids, data);
          }

          printMasterInvoiceRequest(data);
        };

        component = (
          <PrintOptionsForm
            closeModal={closeModal}
            submitAction={submitAction}
            initialValues={initialValues}
            templateOptions={templateOptions}
            documentTypeOptions={documentTypeOptions}
            useVendorDocuments={G.isNotNil(masterInvoiceGuid)}
            vendorDocumentTypeOptions={R.pathOr([], ['carrierDocumentTypeOptions'], configs)}
          />
        );
      }

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          maxWidth: 330,
          maxHeight: '95vh',
          width: 'fit-content',
          title: G.getWindowLocale('titles:print-options', 'Print Options'),
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleOpenUpdateMasterInvoiceInvoices: (props: Object) => (data: Object, callback: Function) => {
      const {
        configs,
        openModal,
        closeModal,
        branchGuid,
        openFixedPopup,
        closeFixedPopup,
        documentTemplates,
        updateMasterInvoiceInvoices,
      } = props;

      const update = G.isFunction(callback) ?
        (data: Object) => {
          updateMasterInvoiceInvoices(R.assoc('callback', callback, data));
        } :
        updateMasterInvoiceInvoices;

      const component = (
        <UpdateInvoices
          data={data}
          asyncMethod='post'
          openModal={openModal}
          closeModal={closeModal}
          branchGuid={branchGuid}
          openFixedPopup={openFixedPopup}
          closeFixedPopup={closeFixedPopup}
          updateMasterInvoiceInvoices={update}
          documentTemplates={documentTemplates}
          asyncEndpoint={endpointsMap.customerMasterInvoiceInvoicesForUpdate}
          initialFilterDays={G.getPropFromObject('invoiceFilterDateRange', configs)}
          documentTypeGuids={R.pathOr(null, ['configs', 'defaultDocumentTypeGuids'], props)}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          top: 35,
          minWidth: 900,
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '95vh',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
    handleOpenSubmitDeleteMasterInvoice: (props: Object) => (guid: string, { masterInvoiceNumber }: Object) => {
      const { openModal, closeModal, deleteMasterInvoicesRequest } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        name: masterInvoiceNumber,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
        submitAction: () => {
          deleteMasterInvoicesRequest({ guids: R.of(Array, guid) });
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleOpenSubmitDeleteMasterInvoices: (props: Object) => () => {
      const { itemList, openModal, closeModal, deleteMasterInvoicesRequest } = props;

      const selectedList = R.filter(R.prop('selected'), itemList);

      if (R.propEq(0, 'length', selectedList)) return;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: R.join(', ', R.map(({ masterInvoiceNumber }: Object) => masterInvoiceNumber, selectedList)),
        text: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
        submitAction: () => {
          deleteMasterInvoicesRequest({ guids: R.map(({ guid }: Object) => guid, selectedList) });
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handlePrintInvoices: (props: Object) => ({ guid }: Object) => {
      const {
        configs,
        openModal,
        closeModal,
        branchGuid,
        configOptions,
        openFixedPopup,
        closeFixedPopup,
        documentTemplates,
        printInvoicesRequest,
        createFactoringDocument,
        availableFactoringTypes,
        createAndExportFactoringDocument,
      } = props;

      const cloDocumentTemplates = G.getPropFromObject(
        GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE,
        documentTemplates,
      );

      if (G.isNilOrEmpty(cloDocumentTemplates)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:no-configured-document-templates', 'There are no configured document templates'),
        );
      }

      const templateOptions = R.map(({ guid, name }: Object) => ({ value: guid, label: name }), cloDocumentTemplates);

      const documentTypeOptions = configOptions.map(({ value, displayedValue }: Object) => ({
        value,
        label: displayedValue,
      }));

      const defaultReplyTo = R.pathOr(null, ['defaultReplyTo'], configs);
      const defaultSendEmails = R.pathOr(null, ['defaultSendEmails'], configs);
      const defaultFactoringName = R.pathOr(null, ['defaultFactoringName'], configs);
      const activeFactoringIntegration = R.path([GC.FIELD_FACTORING_TYPE], availableFactoringTypes);

      const initialValues = {
        [C.DOCUMENT_TEMPLATE]: R.pathOr(null, ['defaultTemplate'], configs),
        [C.DOCUMENT_TYPES]: R.pathOr(null, ['defaultDocumentTypeGuids'], configs),
        [C.VENDOR_DOCUMENT_TYPES]: R.pathOr(null, ['defaultCarrierDocumentTypeGuids'], configs),
        [C.INTEGRATION_TYPE]: G.ifElse(
          R.equals(activeFactoringIntegration, defaultFactoringName),
          defaultFactoringName,
          null,
        ),
      };

      const options = {
        params: {
          [GC.FIELD_INVOICE_MASTER_INVOICE_GUID]: guid,
          documentTypeGuids: R.join(',', R.pathOr([], ['defaultDocumentTypeGuids'], configs)),
        },
      };

      const component = (
        <AsyncPrintInvoicesOptionsForm
          openModal={openModal}
          asyncOptions={options}
          closeModal={closeModal}
          branchGuid={branchGuid}
          masterInvoiceGuid={guid}
          initialValues={initialValues}
          openFixedPopup={openFixedPopup}
          defaultReplyTo={defaultReplyTo}
          closeFixedPopup={closeFixedPopup}
          documentTemplates={documentTemplates}
          defaultSendEmails={defaultSendEmails}
          documentTypeOptions={documentTypeOptions}
          activeFactoringIntegration={activeFactoringIntegration}
          asyncEndpoint={endpointsMap.customerMasterInvoiceInvoices}
          templateOptions={G.addEmptyOptionToDropDown(templateOptions)}
          vendorDocumentTypeOptions={R.pathOr([], ['carrierDocumentTypeOptions'], configs)}
          saveAndSend={(values: Object) => (
            createAndExportFactoringDocument({
              ...values,
              [GC.FIELD_INVOICE_MASTER_INVOICE_GUID]: guid,
            })
          )}
          submitAction={(values: Object) => {
            const requestPayload = {
              ...values,
              [GC.FIELD_INVOICE_MASTER_INVOICE_GUID]: guid,
            };

            if (G.isNotNilAndNotEmpty(values[C.INTEGRATION_TYPE])) {
              return createFactoringDocument(requestPayload);
            }

            printInvoicesRequest(requestPayload);
          }}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          minWidth: 800,
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '95vh',
          title: G.getWindowLocale('titles:print-options', 'Print Options'),
        },
      };

      openModal(modal);
    },
  }),
  withMasterInvoiceActions,
  withHandlers({
    handleExportMasterInvoicesToQuickBook: (props: Object) => () => {
      const { itemList, handleSelectOptionForExport } = props;

      const selectedList = R.filter(R.prop('selected'), itemList);

      if (R.propEq(0, 'length', selectedList)) {
        return G.showToastrMessageSimple('info', selectInvoiceLocaleTxt);
      }

      const guids = R.map(G.getGuidFromObject, selectedList);

      handleSelectOptionForExport({ guids }, 'QB');
    },
    handleExportMasterInvoicesToQuickBookDesktop: (props: Object) => () => {
      const { itemList, handleSelectOptionForExport } = props;

      const selectedList = R.filter(R.prop('selected'), itemList);

      if (R.propEq(0, 'length', selectedList)) {
        return G.showToastrMessageSimple('info', selectInvoiceLocaleTxt);
      }

      const guids = R.map(G.getGuidFromObject, selectedList);

      handleSelectOptionForExport({ guids }, 'QB_DESKTOP');
    },
    handleQBIIFImportList: (props: Object) => (invoiceGuid: string) => {
      const { itemList, handleSelectOptionForExport } = props;

      const guids = G.getGuidsFromStringOrSelectedList(invoiceGuid, itemList);

      if (R.isEmpty(guids)) {
        G.showToastrMessageSimple('info', selectInvoiceLocaleTxt);
      }

      handleSelectOptionForExport({ guids }, 'IFF');
    },
    handleListRequest: ({ getItemListRequest }: Object) => (isInitial: boolean = false) => (
      getItemListRequest(isInitial)
    ),
    getQuickFilteredListRequest: ({ getItemListRequest, resetListAndPagination }: Object) => () => {
      resetListAndPagination();
      getItemListRequest(true);
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), props.reportList);
      props.setUsedReport(selectedReport);
      props.getItemListRequest(true);
    },
    handleChangeReportParams: ({ setReportFromPrompt }: Object) => (data: Object) => (
      setReportFromPrompt(data)
    ),
    handleSetUsedReport: (props: Object) => () => {
      const {
        setUsedReport,
        selectedReport,
        setPromptStatus,
        reportFromPrompt,
        setOriginalReport,
        getItemListRequest,
      } = props;

      if (R.and(
        G.isNotNilAndNotEmpty(reportFromPrompt),
        G.notEquals(selectedReport, reportFromPrompt),
      )) {
        setOriginalReport(selectedReport);
        setUsedReport(reportFromPrompt);
        getItemListRequest(true);
      }

      setPromptStatus(false);
    },
    handleChangeFilterByCLOReference: (props: Object) => (filterString: string) => {
      const { setQuickFilterParams, getItemListRequest, resetListAndPagination } = props;

      let quickFilterParams = {};

      if (G.isNotNilAndNotEmpty(filterString)) {
        quickFilterParams = {
          dataType: 'string',
          dateRelative: false,
          operation: 'contain',
          stringValue: filterString,
          propertyName: GC.GRC.INVOICES_CLO_PRIMARY_REFERENCE_VALUE,
        };
      }

      setQuickFilterParams(quickFilterParams);
      resetListAndPagination();
      getItemListRequest(true);
    },
    handleCleanFilter: ({ cleanQuickFilter, getItemListRequest }: Object) => () => {
      cleanQuickFilter();
      getItemListRequest(true);
    },
    handleOpenAddMasterInvoices: (props: Object) => () => {
      const {
        configs,
        openModal,
        closeModal,
        branchGuid,
        openFixedPopup,
        referenceTypes,
        closeFixedPopup,
        addMasterInvoices,
        documentTemplates,
        updateInvoiceRequest,
      } = props;

      const {
        dateRange,
        autoNumber,
        defaultGroupBy,
        groupByReference,
        invoiceFilterDateRange,
      } = configs;

      const reference = R.compose(
        R.path([groupByReference]),
        R.indexBy(R.prop(GC.FIELD_GUID)),
      )(referenceTypes);

      const asyncEndpoint = G.isAnyTrue(
        G.isNilOrEmpty(defaultGroupBy),
        R.equals(defaultGroupBy, C.GROUP_BY_NO_GROUP),
        R.and(R.equals(defaultGroupBy, C.GROUP_BY_REFERENCE), G.isNilOrEmpty(reference)),
      ) ?
        endpointsMap.customerMasterInvoiceNoGroupInvoices :
        endpointsMap.customerMasterInvoiceGroupedInvoices;

      const component = (
        <AddMasterInvoice
          emptyData={[]}
          showErrMsg={true}
          asyncMethod='post'
          setEmptyData={true}
          openModal={openModal}
          reference={reference}
          closeModal={closeModal}
          branchGuid={branchGuid}
          autoNumber={autoNumber}
          asyncEndpoint={asyncEndpoint}
          openFixedPopup={openFixedPopup}
          defaultGroupBy={defaultGroupBy}
          closeFixedPopup={closeFixedPopup}
          initialMasterInvoiceDays={dateRange}
          addMasterInvoices={addMasterInvoices}
          documentTemplates={documentTemplates}
          initialFilterDays={invoiceFilterDateRange}
          updateInvoiceRequest={updateInvoiceRequest}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          top: 35,
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '85vh',
          minWidth: 'fit-content',
        },
      };

      openModal(modal);
    },
    handleRemoveInvoiceFromMasterInvoice: (props: Object) => (
      invoiceGuid: string,
      invoiceNumber: string,
      masterInvoiceGuid: string,
      callback: Function,
    ) => {
      const { openModal, closeModal, removeInvoiceFromMasterInvoice } = props;

      const modalContent = getConfirmModal({
        name: invoiceNumber,
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
        submitAction: () => {
          removeInvoiceFromMasterInvoice({ callback, invoiceGuid, masterInvoiceGuid });
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleAddCloDocument: (props: Object) => (
      invoice: Object,
      masterInvoiceGuid: string,
      detailsCallback: Function,
    ) => {
      const { openModal, createCloDocumentRequest } = props;

      const cloGuid = R.prop(GC.FIELD_CLO_GUID, invoice);

      const handler = ({ data, callback }: Object) => {
        const valuesToUse = G.mapObjectEmptyStringFieldsToNull(data);

        createCloDocumentRequest({
          detailsCallback,
          masterInvoiceGuid,
          setSubmitting: callback,
          reqData: R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, cloGuid, valuesToUse),
        });
      };

      const component = (
        <DocumentFormWithAsyncCloEvents
          {...props}
          cloGuid={cloGuid}
          load={{ loadType: GC.FIELD_CLO }}
          handleActionLoadDocument={handler}
          initialValues={{ [GC.FIELD_DOCUMENT_PROOF_TYPE]: 'NONE' }}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:add-doc', 'Add Document'),
        },
      };

      openModal(modal);
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  withHandlers({
    handleOpenEditMasterInvoiceForm: (props: Object) => (guid: string, data: Object) => {
      const {
        configs,
        openModal,
        closeModal,
        openLoader,
        branchGuid,
        closeLoader,
        configOptions,
        handleShowAudit,
        handlePrintInvoices,
        customExportRequest,
        handleShowDocuments,
        handleAddCloDocument,
        handleEditCloInvoice,
        availableCustomExport,
        availableFactoringTypes,
        handlePrintMasterInvoice,
        getMasterInvoiceXMLRequest,
        handleSelectOptionForExport,
        handleUpdateMasterInvoiceRequest,
        handleRemoveInvoiceFromMasterInvoice,
        handleOpenUpdateMasterInvoiceInvoices,
        handleExportCustomerMasterInvoiceToEDI,
        handleSendMasterInvoiceListToSageIntacct,
        handleGenerateFactoringFileByFactoringType,
      } = props;

      const endpoint = endpointsMap.getCustomerMasterInvoiceEndpoint(guid);

      const billToLocationName = R.path([GC.GRC.BILL_TO_LOCATION_NAME], data);
      const documentTypeGuids = R.pathOr(null, ['defaultDocumentTypeGuids'], configs);

      const documentTypes = R.filter(
        ({ value }: Object) => R.includes(value, documentTypeGuids),
        configOptions,
      );

      const component = (
        <MasterInvoiceWithCharges
          editMode={true}
          configs={configs}
          masterInvoice={data}
          openModal={openModal}
          branchGuid={branchGuid}
          openLoader={openLoader}
          closeModal={closeModal}
          masterInvoiceGuid={guid}
          asyncEndpoint={endpoint}
          closeLoader={closeLoader}
          documentTypes={documentTypes}
          addDocument={handleAddCloDocument}
          editInvoice={handleEditCloInvoice}
          documentTypeGuids={documentTypeGuids}
          billToLocationName={billToLocationName}
          handleShowDocuments={handleShowDocuments}
          availableCustomExport={availableCustomExport}
          availableFactoringTypes={availableFactoringTypes}
          removeInvoice={handleRemoveInvoiceFromMasterInvoice}
          handleSelectOptionForExport={handleSelectOptionForExport}
          handleSendMasterInvoice={handleUpdateMasterInvoiceRequest}
          handleOpenUpdateMasterInvoiceInvoices={handleOpenUpdateMasterInvoiceInvoices}
          handleExportCustomerMasterInvoiceToEDI={handleExportCustomerMasterInvoiceToEDI}
          handleSendMasterInvoiceListToSageIntacct={handleSendMasterInvoiceListToSageIntacct}
          handleGenerateFactoringFileByFactoringType={handleGenerateFactoringFileByFactoringType}
          // actions
          customExportRequest={customExportRequest}
          handleShowAudit={() => handleShowAudit(data)}
          handlePrintInvoices={() => handlePrintInvoices({ guid })}
          handlePrintMasterInvoice={() => handlePrintMasterInvoice(data)}
          handleGetXMLRequest={() => getMasterInvoiceXMLRequest({
            masterInvoiceGuid: guid,
            fileName: data.masterInvoiceNumber,
          })}
        />
      );

      const modal = G.getFullWindowModalWithContent(component);

      openModal(modal);
    },
  }),
  withPromptModal(FILTER_PARAMS),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    configs,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    openedSidebar,
    configOptions,
    selectedReport,
    openFixedPopup,
    closeFixedPopup,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleAddCloDocument,
    handleEditCloInvoice,
    handleTableTitleFilter,
    getMasterInvoiceDetails,
    handleOpenEditMasterInvoiceForm,
    handleOpenSubmitDeleteMasterInvoice,
    handleRemoveInvoiceFromMasterInvoice,
  } = props;

  const elementActionsComponent = (data: Object) => (
    <AuthWrapper
      has={[PC.CLO_MASTER_INVOICE_READ, PC.CLO_MASTER_INVOICE_WRITE, PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE]}
    >
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const allChecked = G.isAllChecked(itemList);
  let enhancedItemList = itemList;
  const documentTypeGuids = R.pathOr(null, ['defaultDocumentTypeGuids'], configs);

  const documentTypes = R.filter(
    ({ value }: Object) => R.includes(value, documentTypeGuids),
    configOptions,
  );

  if (G.isNotNil(enhancedItemList)) {
    enhancedItemList = R.map(
      (entity: Object) => R.mergeRight(
        entity,
        {
          documentTypes,
          openFixedPopup,
          closeFixedPopup,
          addDocument: handleAddCloDocument,
          editInvoice: handleEditCloInvoice,
          removeInvoice: handleRemoveInvoiceFromMasterInvoice,
          detailsWidth: `calc(100vw - ${
            G.ifElse(
              openedSidebar,
              R.add(GC.SIDEBAR_CONTAINER_WIDTH, 32),
              32,
            )
          }px)`,
        },
      ),
      enhancedItemList,
    );
  }

  const getPermissions = ({ completed }: Object) => G.ifElse(
    G.isTrue(completed),
    [PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE],
    [PC.CLO_MASTER_INVOICE_WRITE, PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE],
  );

  const actionButtons = [
    {
      getPermissions,
      iconName: 'pencil',
      action: handleOpenEditMasterInvoiceForm,
    },
    {
      iconName: 'trash',
      action: handleOpenSubmitDeleteMasterInvoice,
      permissions: [PC.CLO_MASTER_INVOICE_DELETE_EXECUTE],
    },
  ];

  const hasPinned = G.hasPinnedReports(reportList);
  const hasSearchCriteria = G.hasSearchCriteria(selectedReport, filterParams);

  let maxHeight = 'calc(100vh - 145px)';

  if (R.and(hasPinned, hasSearchCriteria)) {
    maxHeight = 'calc(100vh - 200px)';
  } else if (R.or(hasPinned, hasSearchCriteria)) {
    maxHeight = 'calc(100vh - 165px)';
  }

  const data = {
    loading,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    itemList: enhancedItemList,
    withResizableColumns: true,
    useSearchableColumns: true,
    useNewTitleFilterInputs: true,
    toggle: getMasterInvoiceDetails,
    handleLoadMoreEntities: getItemListRequest,
    renderRightStickedComponent: elementActionsComponent,
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), FILTER_PARAMS),
    tableSettings: R.assoc('maxHeight', maxHeight, tableSettings),
  };

  return <Table {...data} />;
};

const getListActions = (props: Object) => {
  const {
    getXMLByReportRequest,
    handleQBIIFImportList,
    handlePrintMasterInvoice,
    handleOpenSubmitDeleteMasterInvoices,
    handleExportMasterInvoicesToQuickBook,
    handleSendMasterInvoiceListToSageIntacct,
    handleExportMasterInvoicesToQuickBookDesktop,
  } = props;

  const mainLightColor = G.getTheme('colors.light.mainLight');

  return [
    {
      action: getXMLByReportRequest,
      icon: I.downloadDocument(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    {
      type: 'massAction',
      permissions: [PC.CLO_INVOICE_WRITE],
      icon: I.quickbook('transparent', 25, 25),
      action: handleExportMasterInvoicesToQuickBook,
      text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
    },
    {
      type: 'massAction',
      permissions: [PC.CLO_INVOICE_WRITE],
      icon: I.quickbook('transparent', 25, 25),
      action: handleExportMasterInvoicesToQuickBookDesktop,
      text: G.getWindowLocale('actions:export-to-QB-desktop', 'Export to QuickBooks Desktop'),
    },
    {
      type: 'massAction',
      icon: I.gear(mainLightColor, 25, 25),
      action: handleSendMasterInvoiceListToSageIntacct,
      text: G.getWindowLocale('actions:send-to-sage-intacct', 'Send To Sage Intacct'),
      permissions: [PC.CLO_MASTER_INVOICE_WRITE, PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE],
    },
    {
      type: 'massAction',
      action: handleQBIIFImportList,
      icon: I.quickbook('transparent', 25, 25),
      text: G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export'),
      permissions: [
        PC.CLO_MASTER_INVOICE_READ,
        PC.CLO_MASTER_INVOICE_WRITE,
        PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE,
      ],
    },
    {
      type: 'massAction',
      icon: I.printer(mainLightColor, 20, 20),
      action: () => handlePrintMasterInvoice(null),
      text: G.getWindowLocale('actions:print', 'Print'),
    },
    {
      type: 'massAction',
      icon: I.trash(mainLightColor, 20, 20),
      action: handleOpenSubmitDeleteMasterInvoices,
      text: G.getWindowLocale('actions:delete', 'Delete'),
      permissions: [PC.CLO_MASTER_INVOICE_DELETE_EXECUTE],
    },
  ];
};

const MasterInvoicesComponent = (props: Object) => {
  const { itemList, selectedReport, handleOpenAddMasterInvoices } = props;

  const selectedList = R.filter(R.prop('selected'), R.or(itemList, []));

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          withCount={true}
          popperWithCount={true}
          filterProps={FILTER_PARAMS}
          usedReport={selectedReport}
          hiddenRightFilterInfo={false}
          type={GC.CUSTOMER_MASTER_INVOICE_REPORT}
          additionalComponent={<FilterByCLOReference {...props} />}
          title={G.getWindowLocale('titles:customer-master-invoices', 'Customer Master Invoice(s)')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      {
        G.isNotEmpty(selectedList) &&
        <AbsoluteBox
          left={65}
          bottom='5px'
          overflow='auto'
          maxWidth='calc(100vw - 420px)'
        >
          <SelectedListTotals list={selectedList} />
        </AbsoluteBox>
      }
      <AuthWrapper has={[PC.CLO_MASTER_INVOICE_WRITE]}>
        <PageActions
          version={2}
          count={R.length(selectedList)}
          listActions={getListActions(props)}
          shadowColor={G.getTheme('colors.light.darkGrey')}
          mainAction={{
            action: handleOpenAddMasterInvoices,
            text: G.getWindowLocale('actions:create-master-invoices', 'Create Master Invoices'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  branchesTree: makeSelectBranchesTree(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  openedSidebar: makeSelectOpenedSidebar(state),
  configs: makeSelectMasterInvoiceConfigs(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  titleSortValues: makeSelectTitleSortValues(state),
  configOptions: makeSelectDocumentTypeOptions(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  documentTemplates: makeSelectDocumentTemplates(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  availableCustomExport: makeSelectAvailableCustomExport(state),
  referenceTypes: makeSelectAllAvailableCloReferenceTypes(state),
  availableFactoringTypes: makeSelectAvailableFactoringTypes(state),
});

export default connect(mapStateToProps, {
  openModal,
  openLoader,
  closeModal,
  selectItem,
  setReports,
  closeLoader,
  setUsedReport,
  cleanQuickFilter,
  addMasterInvoices,
  setTableTitleSort,
  qbIIFImportRequest,
  getItemListRequest,
  createReportRequest,
  customExportRequest,
  updateReportRequest,
  setTableTitleFilter,
  printInvoicesRequest,
  setQuickFilterParams,
  updateInvoiceRequest,
  printByReportRequest,
  getXMLByReportRequest,
  sendToQuickBookRequest,
  resetListAndPagination,
  printCloInvoiceRequest,
  getMasterInvoiceDetails,
  exportReportDataRequest,
  updateCloInvoiceRequest,
  createFactoringDocument,
  createCloDocumentRequest,
  sendToSageIntacctRequest,
  printMasterInvoiceRequest,
  changeDefaultReportRequest,
  getMasterInvoiceXMLRequest,
  deleteMasterInvoicesRequest,
  updateMasterInvoiceInvoices,
  getFactoringCompanyFileRequest,
  removeInvoiceFromMasterInvoice,
  handleUpdateMasterInvoiceRequest,
  createAndExportFactoringDocument,
  exportCustomerMasterInvoiceToEDIRequest,
})(enhance(MasterInvoicesComponent));
