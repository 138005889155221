import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, withProps } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms/formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncInitialData } from '../../../hocs/with-async-initial-data';
// ui
import { Box, Flex, Text } from '../../../ui';
// feature master-invoice
import { ChargeItemFuelRelated } from './charges-ui';
import { validationSchemaMasterChargeObject } from '../validators';
import { rateTypeOptions, rateUnitOptionsGroup } from '../../payroll/settings/payroll-with-charges-settings';
//////////////////////////////////////////////////

const accessorialFieldsToOmit = [
  GC.FIELD_GUID,
  GC.FIELD_VERSION,
  GC.FIELD_CREATED_BY,
  GC.FIELD_CREATED_DATE,
  GC.FIELD_BRANCH_GUID,
  GC.FIELD_LAST_MODIFIED_BY,
  GC.FIELD_ACCESSORIAL_COPY,
  GC.FIELD_LAST_MODIFIED_DATE,
  GC.FIELD_ACCESSORIAL_SERVICE,
  GC.FIELD_ACCESSORIAL_PARENT_GUID,
  GC.FIELD_ACCESSORIAL_FUEL_RELATED,
  GC.FIELD_ACCESSORIAL_TEL_INVOICE_GL,
  GC.FIELD_ACCESSORIAL_CLO_INVOICE_GL,
  GC.FIELD_ACCESSORIAL_ORIGINAL_CONFIG_GUID,
];

const chargeInitFields = {
  [GC.FIELD_GL_CODE]: null,
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_CHARGE_RATE]: '',
  [GC.FIELD_CHARGE_TOTAL]: 0,
  [GC.FIELD_CHARGE_QUANTITY]: 1,
  [GC.FIELD_CHARGE_DISCOUNT]: false,
  [GC.FIELD_CHARGE_RATE_NAME]: null,
  [GC.FIELD_CHARGE_RATE_TYPE]: null,
  [GC.FIELD_CHARGE_RATE_UNIT]: null,
  [GC.FIELD_CHARGE_NON_TAXABLE]: false,
};

const enhance = compose(
  withProps(({ vendorGuid }: Object) => ({
    asyncOptions: { params: {
      vendorGuid,
      [GC.BRANCH_GUID]: G.ifElse(R.isNil(vendorGuid), G.getAmousCurrentUserBranchGuidFromWindow()),
    }},
  })),
  withAsyncInitialData,
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaMasterChargeObject,
    handleSubmit: (values: Object, { props: { submitAction } }: Object) => submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(chargeInitFields, initialValues),
  }),
  pure,
);

const commonInputWrapperStyles = { mr: 20, mb: 25 };

const flexInputWrapperStyles = { ...commonInputWrapperStyles, flexGrow: 1 };

const getUpdatedValues = (fieldValue: string, accessorials: Array, initCharge: Object, glCodeMappings: Object) => {
  const accessorial = R.find(R.propEq(fieldValue, GC.FIELD_ACCESSORIAL_DISPLAYED_VALUE), accessorials);
  const assessorialConfigGuid = G.getPropFromObject(GC.FIELD_ACCESSORIAL_ORIGINAL_CONFIG_GUID, accessorial);
  const glCode = R.pathOr('', [GC.INVOICE_MAPPING_TYPE_ASSESSORIALS, assessorialConfigGuid], glCodeMappings);
  const nonTaxable = R.propOr(false, GC.FIELD_CHARGE_NON_TAXABLE, glCodeMappings);

  return R.compose(
    R.mergeRight({ ...initCharge, glCode, nonTaxable, assessorialConfigGuid }),
    R.omit(accessorialFieldsToOmit),
  )(accessorial);
};

const handleChangeDiscount = ({ target }: Object, { fieldName }: any, { setFieldValue }: Object) => {
  const value = G.getPropFromObject(GC.FIELD_CHECKED, target);
  const type = G.ifElse(value, 'discount', 'additional');

  setFieldValue(fieldName, value);
  setFieldValue(GC.FIELD_CHARGE_TYPE, type);
};

const handleChangeRateOrQuantity = (event: Object, { fieldName }: any, { values, setFieldValue }: Object) => {
  const { rate, quantity } = values;

  const value = G.getEventTargetValue(event);

  setFieldValue(fieldName, G.isNotNilAndNotEmpty(value) ? G.toNumber(value) : '');

  const updatedTotal = R.multiply(
    value,
    R.or(G.ifElse(R.equals(fieldName, GC.FIELD_CHARGE_RATE), quantity, rate), 1),
  );

  setFieldValue(GC.FIELD_CHARGE_TOTAL, updatedTotal);
};

const getFirstFieldSettings = (shouldGenerateId: boolean, type: string, glDisabled: boolean) => [
  {
    isRequired: true,
    type: 'reactSelect',
    closeMenuOnScroll: true,
    shouldCustomChange: true,
    useMenuPortalTarget: true,
    options: 'accessorialsOptions',
    fieldName: GC.FIELD_CHARGE_RATE_NAME,
    label: ['titles:charge-name', 'Charge Name'],
    inputWrapperStyles: { ...flexInputWrapperStyles, flexBasis: 120 },
    customChangeHandler: (value: Object, _: any, props: Object) => {
      const { values, setValues, glCodeMappings, accessorialsConfigs } = props;

      const initCharge = R.compose(
        R.when(() => G.isTrue(shouldGenerateId), R.assoc(GC.FIELD_ID, G.genShortId())),
        R.mergeRight(chargeInitFields),
        R.pick([GC.FIELD_ID, GC.FIELD_COMMENTS, GC.FIELD_DEDUCTION, GC.FIELD_DEDUCTION_FROM_VENDOR]),
      )(values);

      const updatedValues = getUpdatedValues(value, accessorialsConfigs, initCharge, glCodeMappings);

      setValues(updatedValues);
    },
  },
  {
    type: 'number',
    isRequired: true,
    errorWidth: '100%',
    shouldCustomChange: true,
    label: ['titles:rate', 'Rate'],
    fieldName: GC.FIELD_CHARGE_RATE,
    inputStyles: { pl: 10, pr: 10 },
    customChangeHandler: handleChangeRateOrQuantity,
    inputWrapperStyles: { ...flexInputWrapperStyles, maxWidth: 85, flexBasis: 65 },
  },
  {
    type: 'select',
    disabled: true,
    isRequired: true,
    shouldCustomChange: true,
    options: rateTypeOptions,
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    label: ['titles:rate-type', 'Rate Type'],
    inputWrapperStyles: { ...flexInputWrapperStyles, maxWidth: 100, flexBasis: 85 },
    customChangeHandler: (event: Object, _: Object, { handleChange, setFieldValue }: Object) => {
      handleChange(event);

      setFieldValue(GC.FIELD_CHARGE_RATE_UNIT, '');
    },
  },
  {
    type: 'select',
    disabled: true,
    options: 'rateUnitOptions',
    fieldName: GC.FIELD_CHARGE_RATE_UNIT,
    label: ['titles:rate-unit', 'Rate Unit'],
    inputWrapperStyles: {
      ...flexInputWrapperStyles,
      flexBasis: 90,
      maxWidth: 110,
      display: ({ values: { rateType } }: Object) => G.ifElse(
        R.or(G.isNilOrEmpty(rateType), R.includes(rateType, [GC.CHARGE_RATE_TYPE_FLAT, GC.CHARGE_RATE_TYPE_STOP])),
        'none',
        'block',
      ),
    },
  },
  {
    type: 'number',
    isRequired: true,
    errorWidth: '100%',
    shouldCustomChange: true,
    inputStyles: { pl: 10, pr: 10 },
    fieldName: GC.FIELD_CHARGE_QUANTITY,
    label: ['titles:quantity', 'Quantity'],
    customChangeHandler: handleChangeRateOrQuantity,
    inputWrapperStyles: { ...commonInputWrapperStyles, width: 65 },
  },
  {
    type: 'select',
    options: 'glCodeOptions',
    disabled: () => glDisabled,
    fieldName: GC.FIELD_GL_CODE,
    label: ['titles:gl-code', 'GL Code'],
    inputWrapperStyles: {
      ...flexInputWrapperStyles,
      mr: 0,
      maxWidth: 100,
      flexBasis: 80,
    },
  },
];

const secondFieldSettings = [
  {
    type: 'toggle',
    shouldCustomChange: true,
    fieldName: GC.FIELD_CHARGE_DISCOUNT,
    label: ['titles:discount', 'Discount'],
    customChangeHandler: handleChangeDiscount,
    inputWrapperStyles: { ...flexInputWrapperStyles, width: 'fit-content', flexGrow: 0 },
  },
  {
    inputWrapperStyles: { ...flexInputWrapperStyles, width: 'fit-content', flexGrow: 0 },
    type: 'customComponent',
    fieldName: GC.FIELD_CHARGE_NON_TAXABLE,
    Component: ({ values }: Object) => (
      <ChargeItemFuelRelated charge={values} />
    ),
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_COMMENTS,
    label: ['titles:comments', 'Comments'],
    inputWrapperStyles: { mb: 25, width: '100%' },
    inputStyles: { height: 60, minHeight: 48, maxHeight: 200, resize: 'vertical' },
  },
];

const TotalComponent = ({ values, payrollCurrency }: Object) => {
  const deduction = G.getPropFromObject(GC.FIELD_DEDUCTION, values);
  const total = G.NaNToZero(G.toFixed(G.getTotalFromCharge(values), 2));

  const currencySymbol = G.getCurrencySymbol(
    R.or(G.getPropFromObject(GC.FIELD_CHARGE_CURRENCY, values), payrollCurrency),
  );

  const text = `${currencySymbol} ${G.ifElse(G.isTrue(deduction), '-', '')}${total}`;

  return (
    <Flex mb={5} justifyContent='center' color={G.getTheme('colors.dark.blue')}>
      <Text fontSize={14} fontWeight='bold' wordBreak='normal'>
        {`${G.getWindowLocale('titles:total', 'Total')}:`}
      </Text>
      <TextComponent ml={10} title={text} fontSize={14} fontWeight='bold' withEllipsis={true}>
        {text}
      </TextComponent>
    </Flex>
  );
};

const getOptionsFromAccessorials = R.map(({ displayedValue }: Object) => ({
  [GC.FIELD_VALUE]: displayedValue,
  [GC.FIELD_LABEL]: displayedValue,
}));

export const MasterInvoiceChargeForm = enhance((props: Object) => {
  const {
    type,
    values,
    glDisabled,
    asyncConfigs,
    handleSubmit,
    glCodeMappings,
    payrollCurrency,
    shouldGenerateId,
    accessorialsConfigs,
  } = props;

  const { rateType } = values;

  const glCodeOptions = G.addEmptyOptionToDropDown(
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid(asyncConfigs, GC.INVOICE_GL_CODE),
    G.getWindowLocale('titles:gl-code', 'GL Code'),
  );

  return (
    <Box mt={10}>
      <form onSubmit={handleSubmit}>
        <Fieldset2
          {...G.getFormikPropsToFieldset(props)}
          glCodeMappings={glCodeMappings}
          glCodeOptions={R.or(glCodeOptions, [])}
          accessorialsConfigs={accessorialsConfigs}
          fields={getFirstFieldSettings(shouldGenerateId, type, glDisabled)}
          additionFieldComponentProps={['glCodeMappings', 'accessorialsConfigs']}
          accessorialsOptions={getOptionsFromAccessorials(R.or(accessorialsConfigs, []))}
          rateUnitOptions={G.prependEmptyLabelValueOption(G.getPropFromObject(rateType, rateUnitOptionsGroup))}
        />
        <Fieldset2 {...G.getFormikPropsToFieldset(props)} fields={secondFieldSettings} />
        <TotalComponent values={values} payrollCurrency={payrollCurrency} />
        <FormFooter2 />
      </form>
    </Box>
  );
});
