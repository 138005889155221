import * as R from 'ramda';
import { put, all, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { getAllAvailableRefTypesByScopeRequest } from '../../reference/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { generateDefaultReport } from '../../../report-common';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature invoice/carrier
import * as A from './actions';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(payload)) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
        G.isNilOrEmpty(availableReports),
        R.equals('carrier-invoiceDefault', G.getGuidFromObject(reportParams)),
      )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const systemFields = [
      GC.FIELD_CURRENCY,
      GC.FIELD_TEL_GUID,
      GC.GRC.TEL_RATE_GUID,
    ];

    const { limit, offset } = pagination;

    const reqBody = {
      limit,
      offset,
      orderFields,
      systemFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: R.pathOr([], ['fields'], reportParams),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.carrierInvoiceList,
      { data: reqData },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga');
    }

    yield put(closeLoader());
    yield put(A.setListLoading(false));
  } catch (err) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleGetCarrierInvoiceDetailsSaga({ payload }: Object) {
  try {
    yield put(A.toggleCarrierInvoiceDetails(payload.guid));
    if (
      R.and(
        R.not(payload.expanded),
        R.isNil(R.prop('details', payload)),
      )
    ) {
      yield put(A.setCarrierInvoiceDetailsLoading(payload.guid));
      const res = yield call(sendRequest, 'get', endpointsMap.getReconciliation(payload.guid));
      if (G.isResponseSuccess(res.status)) {
        yield put(A.getCarrierInvoiceDetailsSuccess({
          details: res.data,
          guid: payload.guid,
        }));
      } else {
        yield put(A.getCarrierInvoiceDetailsError(payload.guid));
        yield call(G.handleFailResponse, res);
      }
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetCarrierInvoiceDetailsSaga exception');
  }
}

function* handlePrintCarrierSaga({ payload }: Object) {
  try {
    const options = {
      data: {
        format: 'pdf',
        guid: R.head(payload.guids),
        templateGuid: payload.invoiceTemplates,
        documentsGuids: R.or(payload.documents, []),
      },
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.carrierInvoiceExport,
      options,
    );
    if (G.isResponseSuccess(res.status)) {
      const reqOptions = {
        params: {
          fileName: res.data.documentFilename,
          primaryObjectGuid: res.data.primaryObjectGuid,
        },
        resType: 'arraybuffer',
      };
      const fileRes = yield call(
        sendRequest,
        'get',
        endpointsMap.telDocumentDownloadFile,
        reqOptions,
      );
      if (G.isResponseSuccess(fileRes.status)) {
        const file = new window.Blob([fileRes.data], { type: 'application/pdf' });
        G.openFile(file);
      } else {
        yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
      }
    } else {
      yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePrintCarrierSaga exception');
  }
}

function* handleAvailableReportsRequest({ payload, notSetUsedReport }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    if (G.isNilOrEmpty(currentBranchGuid)) return false;
    const reportType = payload.reportType;
    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });
    if (G.isResponseSuccess(res.status)) {
      const reports = G.getReportsSortedBySeqFreez(res.data);
      yield put(A.setReports(reports));
      if (R.not(R.prop('length', reports))) {
        return yield put(A.setUsedReport(generateDefaultReport(GC.CARRIER_INVOICE_REPORT)));
      }
      if (R.not(notSetUsedReport)) {
        const defaultReport = G.findDefaultReport(reports);
        const usedReport = R.or(defaultReport, generateDefaultReport(GC.CARRIER_INVOICE_REPORT));
        yield put(A.setUsedReport(usedReport));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
  }
}

function* handleChangeInvoicesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { guids } = payload;

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    let options = {
      data: R.assoc(GC.FIELD_CURRENT_BRANCH, currentBranchGuid, payload),
    };

    if (G.isNilOrEmpty(guids)) {
      const report = yield select(makeSelectUsedReport());
      const filterParams = yield select(makeSelectFilterParams());

      const reqBody = {
        ...payload,
        searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(report, 'searchCriteria', [])),
      };

      options = {
        data: G.setSearchCriteria({ reqBody, filterParams }),
      };
    }

    const res = yield call(sendRequest, 'post', endpointsMap.carrierInvoiceMassUpdate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { errors, updatedInvoices } = data;

      if (G.isNotNilAndNotEmpty(errors)) {
        yield all(R.map((item: string) => call(G.showToastrMessage, 'error', item), errors));
      }

      if (R.and(G.isNotNilAndNotEmpty(guids), G.isNotNilAndNotEmpty(updatedInvoices))) {
        yield put(A.changeInvoicesSuccess({ guids, data: updatedInvoices }));
      } else {
        yield put(A.resetListAndPagination());
        yield put(A.getItemListRequest(true));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeInvoicesSaga', true);
    }
    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeInvoicesSaga exception');
  }
}

function* handleDeleteCarrierInvoicesSaga({ payload }: Object) {
  try {
    const { guids } = payload;
    yield put(openLoader({ showDimmer: true }));
    let options = {
      data: payload,
    };

    if (G.isNilOrEmpty(guids)) {
      const report = yield select(makeSelectUsedReport());
      const filterParams = yield select(makeSelectFilterParams());
      const reqBody = {
        ...payload,
        searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(report, 'searchCriteria', [])),
      };
      options = {
        data: G.setSearchCriteria({ reqBody, filterParams }),
      };
    }
    const res = yield call(sendRequest, 'delete', endpointsMap.telCarrierInvoice, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierInvoicesSaga');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteCarrierInvoicesSaga exception');
  }
}

function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const data = R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, payload);
    const res = yield call(sendRequest, 'post', endpointsMap.report, { data });
    if (G.isResponseSuccess(res.status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(res.data)));
      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            pathname: GC.ROUTE_PATH_CARRIER_INVOICES_LIST,
            reportType: GC.CARRIER_INVOICE_REPORT,
          },
          notSetUsedReport: true,
        },
      );
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
  }
}

function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.report, { data: payload });
    if (G.isResponseSuccess(res.status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(res.data)));
      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            pathname: GC.ROUTE_PATH_PAYROLL_REPORT_LIST,
            reportType: GC.CARRIER_INVOICE_REPORT,
          },
          notSetUsedReport: true,
        },
      );
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
  }
}

function* handleUpdateCIReconciliationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: payload,
    };
    const res = yield call(sendRequest, 'put', endpointsMap.telCarrierInvoice, options);
    if (G.isResponseSuccess(res.status)) {
      yield put(A.updateCIReconciliationSuccess(res.data));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCIReconciliationSaga');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateCIReconciliationSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const filterParams = yield select(makeSelectFilterParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const { fields, fileType, orderFields } = payload;

    const reqBody = {
      fields,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(payload, 'searchCriteria', [])),
    };

    const data = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const params = { format: fileType };

    const options = {
      data,
      params,
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequest, 'post', endpointsMap.carrierInvoiceListExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
  }
}

function* handleApproveOrRejectCarrierInvoiceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { requestData, endpointName } = payload;
    const endpoint = R.prop(endpointName, endpointsMap);
    const res = yield call(sendRequest, 'put', endpoint, { data: requestData });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.approveOrRejectCarrierInvoiceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleApproveOrRejectCarrierInvoiceSaga');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleApproveOrRejectCarrierInvoiceSaga exception');
  }
}

function* handleExportCarrierInvoiceToQuickBooksSaga({ payload }: Object) {
  try {
    const { guids, desktop, currentEnterprise } = payload;

    yield put(openLoader({ showDimmer: true }));

    let options = {
      data: {
        guids,
        [GC.FIELD_CURRENT_BRANCH]: currentEnterprise,
      },
    };

    const endpoint = G.isTrue(desktop) ?
      endpointsMap.carrierInvoiceSendToQuickBooksDesktop : endpointsMap.carrierInvoiceSendToQuickBooks;

    if (G.isNilOrEmpty(guids)) {
      const report = yield select(makeSelectUsedReport());
      const filterParams = yield select(makeSelectFilterParams());

      const reqBody = {
        [GC.FIELD_CURRENT_BRANCH]: currentEnterprise,
        searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(report, 'searchCriteria', [])),
      };
      options = {
        data: G.setSearchCriteria({ reqBody, filterParams }),
      };
    }

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.errors)) {
        G.handlePartialSuccessErrors(data.errors);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleExportCarrierInvoiceToQuickBooksSaga');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportCarrierInvoiceToQuickBooksSaga exception');
  } finally {
    yield put(closeModal());
    yield put(closeLoader());
  }
}

function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    if (G.isResponseSuccess(res.status)) {
      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            pathname: GC.ROUTE_PATH_CARRIER_INVOICES_LIST,
            reportType: GC.CARRIER_INVOICE_REPORT,
          },
        },
      );
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
  }
}

function* handleGetConfigsByNamesSaga({ payload }: Object) {
  try {
    const { branchGuid, names } = payload;
    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);
      yield put(A.getConfigsByNamesSuccess(mappedConfigs));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetConfigsByNamesSaga');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetConfigsByNamesSaga exception');
  }
}

function* handlePrintByReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const reportParams = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const reqBody = {
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };
    const options = {
      params: payload,
      resType: 'arraybuffer',
      data: G.setSearchCriteria({ filterParams: requestFilterParams, reqBody }),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.carrierInvoicePrintByReport, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, GC.CARRIER_INVOICE_REPORT);
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handlePrintByReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePrintByReportSaga exception');
  }
}

function* handleGetXMLByReportSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const reportParams = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const reqBody = {
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };
    const options = {
      resType: 'arraybuffer',
      data: G.setSearchCriteria({ filterParams: requestFilterParams, reqBody }),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.carrierInvoiceXml, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, `${GC.CARRIER_INVOICE_REPORT}.xml`);
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleGetXMLByReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetXMLByReportSaga exception');
  }
}

function* handleQbIIFImportSaga({ payload }: Object) {
  try {
    const options = {
      data: payload,
      resType: 'arraybuffer',
    };
    const res = yield call(sendRequest, 'post', endpointsMap.carrierInvoiceExportIIF, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, `${R.head(payload)}.txt`);
    } else {
      yield call(G.handleFailResponse, G.convertArrayBufferFailResponse(res), 'handleQbIIFImportSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleQbIIFImportSaga exception');
  }
}

function* getCarrierInvoiceStatusesSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.carrierInvoiceStatuses, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierInvoiceStatusesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getCarrierInvoiceStatusesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierInvoiceStatusesSaga exception');
  }
}

function* handleVisitCarrierInvoicesPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_INVOICE_CARRIER_LIST_PAGE);

    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());
    yield put(getAllAvailableRefTypesByScopeRequest(GC.REF_SCOPE_NAME_CARRIER_INVOICE));

    yield call(getCarrierInvoiceStatusesSaga);

    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload });
    yield call(handleGetItemListSaga, { payload: true });

    yield put(closeLoader());

    break;
  }
}

function* carrierInvoicesWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.qbIIFImportRequest, handleQbIIFImportSaga);
  yield takeLatest(A.printByReportRequest, handlePrintByReportSaga);
  yield takeLatest(A.getXMLByReportRequest, handleGetXMLByReportSaga);
  yield takeLatest(A.changeInvoicesRequest, handleChangeInvoicesSaga);
  yield takeLatest(A.printCarrierInvoiceRequest, handlePrintCarrierSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.getConfigsByNamesRequest, handleGetConfigsByNamesSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.getCarrierInvoiceDetails, handleGetCarrierInvoiceDetailsSaga);
  yield takeLatest(A.deleteCarrierInvoicesRequest, handleDeleteCarrierInvoicesSaga);
  yield takeLatest(A.updateCIReconciliationRequest, handleUpdateCIReconciliationSaga);
  yield takeLatest(GC.VISIT_INVOICE_CARRIER_LIST_PAGE, handleVisitCarrierInvoicesPageSaga);
  yield takeLatest(A.approveOrRejectCarrierInvoiceRequest, handleApproveOrRejectCarrierInvoiceSaga);
  yield takeLatest(A.exportCarrierInvoiceToQuickBooksRequest, handleExportCarrierInvoiceToQuickBooksSaga);
}

export default carrierInvoicesWatcherSaga;
