import * as R from 'ramda';
import { put, all, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost } from '../../../components/edit-report/helpers';
// features
import { getConfigByGroupSaga } from '../../configurations/sagas';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { getAccessorialForConfigRequest } from '../../configurations/actions';
import { getAllAvailableRefTypesByScopeRequestSaga } from '../../reference/sagas';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getReportSagas } from '../../../report-common';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature invoice
import { COMPLETED } from '../constants';
import { getInvoiceConfigByGroupSaga } from '../sagas';
// feature invoice/driver
import * as A from './actions';
import * as S from './selectors';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectAdditionalFilters,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());
    const additionalFilters = yield select(makeSelectAdditionalFilters());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.includes('Default', reportParams.guid),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const cloBranchGuidFieldName = `${GC.FIELD_CLO}.${GC.BRANCH_GUID}`;

    const reqBody = {
      ...additionalFilters,
      guids,
      orderFields,
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      // TODO: check transformReferenceOrderFields
      // orderFields: G.transformReferenceOrderFields(G.getOrElse(reportParams, 'orderFields', [])),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      fields: R.concat(
        G.getOrElse(reportParams, 'fields', []),
        [
          { name: COMPLETED, freezed: false, sequence: 101, reference: false },
          { name: GC.FIELD_STATUS, freezed: false, sequence: 102, reference: false },
          { name: GC.FIELD_CLO_GUID, freezed: false, sequence: 100, reference: false },
          { name: cloBranchGuidFieldName, freezed: false, sequence: 103, reference: false },
          { name: GC.FIELD_SYSTEM_STATUS, freezed: false, sequence: 104, reference: false },
        ],
      ),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.customerInvoiceList,
      { data: reqData },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { name, fields, fileType, orderFields, searchCriteria, avoidRelatedValueDuplicates } = payload;

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const filterParams = yield select(makeSelectFilterParams());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const reqBody = {
      fields,
      avoidRelatedValueDuplicates,
      [GC.FIELD_REPORT_NAME]: name,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.transformReferenceOrderFields(orderFields),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const data = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const params = { format: fileType };

    const options = {
      data,
      params,
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequest, 'post', endpointsMap.customerInvoiceExportReport, options);

    if (G.isResponseSuccess(res.status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
  }
}

function* handlePrintSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { data: payload };

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.customerInvoiceExport,
      options,
    );

    if (G.isResponseSuccess(res.status)) {
      const reqOptions = {
        params: {
          fileName: res.data.documentFilename,
          primaryObjectGuid: res.data.primaryObjectGuid,
        },
        resType: 'arraybuffer',
      };

      const fileRes = yield call(
        sendRequest,
        'get',
        endpointsMap.cloDocumentDownloadFile,
        reqOptions,
      );

      if (G.isResponseSuccess(fileRes.status)) {
        yield put(closeModal());

        const file = new window.Blob([fileRes.data], { type: 'application/pdf' });

        G.openFile(file);
      } else {
        yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
      }
    } else {
      yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePrintCISaga exception');
  }
}

function* handleDeleteItemSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'delete', endpointsMap.cloInvoice, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteItemSuccess(R.pathOr([], ['guids'], payload)));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCustomerInvoicesSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteCustomerInvoicesSaga exception');
  }
}

function* handleCreateReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.customerInvoiceReference,
      { data: payload },
    );

    if (G.isResponseSuccess(res.status)) {
      yield put(A.createReferenceSuccess(R.head(res.data)));
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReferenceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateReferenceSaga exception');
  }
}

function* handleSendToFinancialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { guids, desktop } = payload;

    const payloadToUse = R.omit('desktop', payload);

    let options = {
      data: payloadToUse,
    };

    const endpoint = G.isTrue(desktop) ?
      endpointsMap.customerInvoiceToQuickBooksDesktop : endpointsMap.customerInvoiceFinancialExport;

    if (G.isNilOrEmpty(guids)) {
      const report = yield select(makeSelectUsedReport());
      const filterParams = yield select(makeSelectFilterParams());
      const additionalFilters = yield select(makeSelectAdditionalFilters());

      const reqBody = {
        ...payloadToUse,
        ...additionalFilters,
        searchCriteria: G.getOrElse(report, 'searchCriteria', []),
      };

      options = {
        data: G.setSearchCriteria({ reqBody, filterParams }),
      };
    }

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:request-processing');
    } else {
      yield call(G.handleFailResponse, res, 'handleSendToFinancialSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSendToFinancialSaga exception');
  }
}

function* handleChangeInvoicesSaga({ payload }: Object) {
  try {
    const { guids } = payload;

    yield put(openLoader({ showDimmer: true }));

    let options = { data: payload };

    if (G.isNilOrEmpty(guids)) {
      const report = yield select(makeSelectUsedReport());
      const filterParams = yield select(makeSelectFilterParams());
      const additionalFilters = yield select(makeSelectAdditionalFilters());

      const reqBody = {
        ...payload,
        ...additionalFilters,
        searchCriteria: G.getOrElse(report, 'searchCriteria', []),
      };

      options = {
        data: G.setSearchCriteria({ reqBody, filterParams }),
      };
    }

    const res = yield call(sendRequest, 'post', endpointsMap.customerInvoiceMassUpdate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { errors, updatedInvoices } = data;

      if (G.isNotNilAndNotEmpty(errors)) {
        yield all(R.map((item: string) => call(G.showToastrMessage, 'error', item), errors));
      }

      if (R.and(G.isNotNilAndNotEmpty(guids), G.isNotNilAndNotEmpty(updatedInvoices))) {
        yield put(A.changeInvoicesSuccess({ guids, data: updatedInvoices }));
      } else {
        yield put(A.resetListAndPagination());
        yield put(A.getItemListRequest(true));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeInvoicesSaga fail', true);
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeInvoicesSaga exception');
  }
}

const getReadyForBillingEndpointAndOptions = (payload: Object) => {
  const { data, ready, values } = payload;

  const guids = R.of(Array, R.path([GC.FIELD_GUID], data));
  const statusConfigGuid = R.path([GC.FIELD_STATUS], values);

  const endpoint = G.ifElse(
    G.isFalse(ready),
    endpointsMap.customerInvoiceMarkAsReadyForBilling,
    endpointsMap.customerInvoiceUnamrkAsReadyForBilling,
  );

  if (G.isFalse(ready)) {
    const options = {
      data: guids,
    };

    return { options, endpoint };
  }

  const options = {
    data: {
      guids,
      statusConfigGuid,
    },
  };

  return { options, endpoint };
};

function* handleReadyForBillingRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const guids = R.of(Array, R.path(['data', GC.FIELD_GUID], payload));

    const { options, endpoint } = getReadyForBillingEndpointAndOptions(payload);

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.readyForBillingSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleReadyForBillingRequestSaga fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleReadyForBillingRequestSaga exception');
  }
}

function* handleGetConfigsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      [GC.FIELD_BRANCH_GUID]: payload,
      group: GC.INVOICE_CONFIG_GROUP,
    };

    const res = yield call(getInvoiceConfigByGroupSaga, options);

    if (G.isResponseSuccess(res.status)) {
      yield put(A.getConfigsSuccess(G.mapConfigValuesByName(res.data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetConfigsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetConfigsSaga exception');
  }
}

function* updateCloInvoiceRequest({ payload }: Object) {
  try {
    const { invoice } = payload;

    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.cloInvoice, { data: invoice });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(handleGetItemListSaga, { payload: { openLoader: true, guids: R.of(Array, G.getGuidFromObject(data)) } });

      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateCloInvoiceRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateCloInvoiceRequest exception');
  }
}

function* getOrderInvoiceXMLRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.getCloInvoiceXMLEndpoint(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'getOrderInvoiceXMLRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'getOrderInvoiceXMLRequest exception');
  }
}

function* printInvoiceListRequest({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        ...payload,
        format: GC.EXTENSION_PDF,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.customerInvoicesPrint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'printInvoiceListRequest fail');
    }

  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printInvoiceListRequest exception');
  }
}

function* handleGetOrderBillToRequestSaga({ payload }: Object) {
  try {
    const { cloGuid, callback } = payload;

    const options = {
      params: { cloGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.cloBillTo, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      G.callFunctionWithArgs(callback, data);
    } else {
      yield call(G.handleFailResponse, res, 'handleGetOrderBillToRequestSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetOrderBillToRequestSaga exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handlePrintByReportSaga,
  handleGetXMLByReportSaga,
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.CUSTOMER_INVOICE_REPORT,
  A,
  handleGetItemListSaga,
  { S, createUpdateReportSuccessCallback },
);

function* handleVisitCustomerInvoicesPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_INVOICE_CUSTOMER_LIST_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport(['additionalFilters']));
    } else {
      yield put(A.setInitialState());
    }

    yield call(getConfigByGroupSaga, GC.INVOICE_CONFIG_GROUP);

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    yield put(A.getConfigsRequest(branchGuid));
    yield put(A.setIgnorePromptStatus(false));

    yield call(getAllAvailableRefTypesByScopeRequestSaga, { payload: GC.REF_SCOPE_NAME_CUSTOMER_INVOICE });
    yield call(getAllAvailableRefTypesByScopeRequestSaga, { payload: GC.REF_SCOPE_NAME_CLO });

    yield put(getAccessorialForConfigRequest());
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* customerInvoicesWatcherSaga() {
  yield takeLatest(A.printRequest, handlePrintSaga);
  yield takeLatest(A.deleteItemRequest, handleDeleteItemSaga);
  yield takeLatest(A.getConfigsRequest, handleGetConfigsSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.printByReportRequest, handlePrintByReportSaga);
  yield takeLatest(A.getXMLByReportRequest, handleGetXMLByReportSaga);
  yield takeLatest(A.changeInvoicesRequest, handleChangeInvoicesSaga);
  yield takeLatest(A.updateCloInvoiceRequest, updateCloInvoiceRequest);
  yield takeLatest(A.printInvoiceListRequest, printInvoiceListRequest);
  yield takeLatest(A.createReferenceRequest, handleCreateReferenceSaga);
  yield takeLatest(A.sendToFinancialRequest, handleSendToFinancialSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.getOrderInvoiceXMLRequest, getOrderInvoiceXMLRequest);
  yield takeLatest(A.getOrderBillToRequest, handleGetOrderBillToRequestSaga);
  yield takeLatest(A.readyForBillingRequest, handleReadyForBillingRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(GC.VISIT_INVOICE_CUSTOMER_LIST_PAGE, handleVisitCustomerInvoicesPageSaga);
}

export default customerInvoicesWatcherSaga;
