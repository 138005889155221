import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// components
import TextComponent from '../../../components/text';
import EditableBox from '../../../components/editable-box';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

const tableSettings = {
  allowEditBtn: true,
  tableRowHeight: 45,
  titleRowHeight: 45,
  checkBoxCellWidth: 55,
  searchableTitles: true,
  allowSelectItems: false,
  checkBoxCellJustifyContent: 'flex-start',
};

const report = {
  type: GC.MASTER_INVOICE_UI_REPORTS,
  guid: `${GC.MASTER_INVOICE_UI_REPORTS}ChargesReport`,
  fields: [
    { sequence: 1, name: GC.FIELD_CHARGE_RATE_NAME },
    { sequence: 2, name: GC.FIELD_CHARGE_DISCOUNT },
    { sequence: 3, name: GC.FIELD_CHARGE_RATE },
    { sequence: 4, name: GC.FIELD_CHARGE_RATE_TYPE },
    { sequence: 5, name: GC.FIELD_CHARGE_RATE_UNIT },
    { sequence: 6, name: GC.FIELD_CHARGE_TOTAL },
    { sequence: 7, name: GC.FIELD_CHARGE_QUANTITY },
    { sequence: 8, name: GC.FIELD_GL_CODE },
    { sequence: 9, name: GC.FIELD_CHARGE_NON_TAXABLE },
    { sequence: 10, name: GC.FIELD_COMMENTS },
  ],
};

const defaultReportFields = R.compose(
  R.pluck(GC.FIELD_NAME),
  R.prop('fields'),
)(report);

const getSortedReport = (fieldNames: Array) => ({
  ...report,
  fields: fieldNames.map((name: string, sequence: number) => ({ name, sequence })),
});

const getColumnSettings = (glCodeOptions: Array, accessorialsOptions: Array) => ({
  [GC.FIELD_CHARGE_RATE_NAME]: {
    width: 200,
    name: 'titles:charge-name',
    filter: { type: 'selectMultiple', options: accessorialsOptions },
  },
  [GC.FIELD_CHARGE_DISCOUNT]: {
    width: 150,
    type: 'boolean',
    name: 'titles:discount',
    filter: { type: 'boolean' },
  },
  [GC.FIELD_CHARGE_RATE]: {
    width: 120,
    name: 'titles:rate',
    filter: { type: 'number' },
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    width: 120,
    type: 'enumLocale',
    name: 'titles:rate-type',
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    width: 120,
    type: 'enumLocale',
    name: 'titles:rate-unit',
  },
  [GC.FIELD_CHARGE_TOTAL]: {
    width: 120,
    name: 'titles:total',
    filter: { type: 'number' },
    customComponent: ({ data: { total }, callbackData: { chargeCurrency } }: Object) => {
      const title = `${chargeCurrency} ${G.toFixed(total)}`;

      return (
        <TextComponent
          p='4px 6px'
          title={title}
          maxWidth={85}
          fontWeight={700}
          borderRadius='3px'
          width='fit-content'
          withEllipsis={true}
          bg={G.getTheme('colors.light.blue')}
          color={G.getTheme('colors.light.mainLight')}
        >
          {title}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_CHARGE_QUANTITY]: {
    width: 90,
    name: 'titles:qty',
    filter: { type: 'number' },
  },
  [GC.FIELD_GL_CODE]: {
    width: 150,
    name: 'titles:gl-code',
    filter: { type: 'selectMultiple', options: glCodeOptions },
    customComponent: ({ data: { glCode }, callbackData: { asyncConfigs } }: Object) => {
      if (G.isNilOrEmpty(glCode)) return null;

      const glCodeDisplayedValue = R.compose(
        R.pathOr('', [GC.FIELD_DISPLAYED_VALUE]),
        R.find((item: Object) => R.equals(glCode, G.getParentGuidOrGuidFromObject(item))),
        R.pathOr([], ['dropdowns', GC.INVOICE_GL_CODE, 'options']),
      )(asyncConfigs);

      return (
        <TextComponent display='block' maxWidth='90%' withEllipsis={true} title={glCodeDisplayedValue}>
          {glCodeDisplayedValue}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_CHARGE_NON_TAXABLE]: {
    width: 150,
    type: 'boolean',
    name: 'titles:non-taxable',
    filter: { type: 'boolean' },
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
    customComponent: ({ data, callbackData: { handleUpdateChargeComments } }: Object) => (
      <Flex>
        <EditableBox
          value={R.pathOr('', [GC.FIELD_COMMENTS], data)}
          textareaStyles={{ fontSize: 11, height: '100%' }}
          wrapperStyles={{ py: '5px', height: 39, width: '100%' }}
          onBlurCallback={(comments: string) => handleUpdateChargeComments(comments, data)}
          placeholder={G.getWindowLocale('titles:click-to-add-a-comment', 'Click to add a comment')}
          boxStyles={{ fontSize: 11, height: '100%', display: 'flex', overflow: 'auto', alignItems: 'center' }}
        />
      </Flex>
    ),
  },
});

export {
  report,
  tableSettings,
  getSortedReport,
  getColumnSettings,
  defaultReportFields,
};
