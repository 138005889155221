import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Text, Flex } from '../../../ui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

export const getChargeTotalInfoFromProps = (props: Object) => (
  `${G.getCurrencySymbol(R.pathOr(
    GC.DEFAULT_UI_CURRENCY,
    ['form', 'values', GC.FIELD_MASTER_INVOICE_CURRENCY],
     props,
    ),
  )}
    ${G.toFixedFromString(R.pathOr(
      0,
      ['form', 'values', GC.FIELD_MASTER_INVOICE_CHARGES, props.chargeIndex, GC.FIELD_CHARGE_TOTAL],
       props,
      ),
      2,
    )}
  `
);

export const ChargeTotal = (props: Object) => (
  <TextComponent
    p='2px 10px'
    width='100%'
    fontSize={14}
    borderRadius='3px'
    textAlign='center'
    withEllipsis={true}
    display='inline-block'
    bg={G.getTheme('colors.dark.lightGrey')}
    color={G.getTheme('colors.light.black')}
    title={getChargeTotalInfoFromProps(props)}
  >
    {getChargeTotalInfoFromProps(props)}
  </TextComponent>
);

export const ChargeItemTotal = (props: Object) => {
  const { arrayItem, payrollCurrency } = props;

  const deduction = G.getPropFromObject(GC.FIELD_DEDUCTION, arrayItem);
  const total = G.NaNToZero(G.toFixed(G.getTotalFromCharge(arrayItem), 2));
  const currencySymbol = G.getCurrencySymbol(R.pathOr(payrollCurrency, [GC.FIELD_CHARGE_CURRENCY], arrayItem));

  const text = G.ifElse(
    G.isTrue(deduction),
    `${currencySymbol} -${total}`,
    `${currencySymbol} ${total}`,
  );

  return (
    <Flex mr='3px'>
      <Text
        fontSize={14}
        fontWeight='bold'
        wordBreak='normal'
        color={darkBlueColor}
        textTransform='uppercase'
      >
        {`${G.getWindowLocale('titles:total', 'Total')}:`}
      </Text>
      <TextComponent
        ml={10}
        title={text}
        fontSize={14}
        fontWeight='bold'
        withEllipsis={true}
        color={darkBlueColor}
      >
        {text}
      </TextComponent>
    </Flex>
  );
};

export const ChargeFuelRelated = (props: Object) => (
  <Flex justifyContent='center' width='100%'>
    {G.convertDataTypeToUiField(R.pathOr(
      false,
      ['form', 'values', GC.FIELD_MASTER_INVOICE_CHARGES, props.chargeIndex, GC.FIELD_CHARGE_NON_TAXABLE],
      props))}
  </Flex>
);

export const ChargeItemFuelRelated = (props: Object) => (
  <Flex width='100%' minHeight={30} justifyContent='center'>
    <Box pl='5px' mr='8px' fontSize={11} color={G.getTheme('colors.greyMatterhorn')}>
      {G.getWindowLocale('titles:non-taxable', 'Non Taxable')}
    </Box>
    {G.convertDataTypeToUiField(R.pathOr(
      false,
      ['charge', GC.FIELD_CHARGE_NON_TAXABLE],
      props,
    ))}
  </Flex>
);

const getChargeCommentText = (charge: Object) => R.pathOr(
  G.getWindowLocale('actions:add-comments', 'Add Comments'),
  [GC.FIELD_CHARGE_COMMENTS],
  charge,
);

export const ChargeAdditionInfo = (props: Object) => {
  const { charge, handleOpenChargeComment } = props;

  const commentText = getChargeCommentText(charge);

  return (
    <Flex cursor='pointer'>
      <Box
        onClick={() => handleOpenChargeComment(charge)}
        title={G.getWindowLocale('actions:add-comments', 'Add Comments')}
      >
        {I.comment2()}
      </Box>
      <TextComponent
        px='5px'
        fontSize={12}
        minWidth={250}
        maxWidth={250}
        display='block'
        withEllipsis={true}
        title={commentText}
        color={darkBlueColor}
        onClick={() => handleOpenChargeComment(charge)}
      >
        {commentText}
      </TextComponent>
    </Flex>
  );
};
