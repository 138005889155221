import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../common/selectors';
import {
  exportInvoicesToEDIRequest,
  sendInvoiceToBCByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
  createQBIIFImportByInvoiceTypeRequest,
} from '../../../common/actions';
// components
import { Table } from '../../../components/table';
import { EditReport } from '../../../components/edit-report';
import { TitlePanel } from '../../../components/title-panel';
import { getConfirmModal } from '../../../components/confirm';
import { PageActions } from '../../../components/page-actions';
import { Tabs2, withTabs2 } from '../../../components/tabs-mui';
import { ActionsElement } from '../../../components/actions-element';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { startPivotTable } from '../../pivot-table/actions';
import PrintForm from '../../dispatch-details-new/forms/print-form';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectPivotTableTotalCount } from '../../pivot-table/selectors';
import ReferenceFormComponent from '../../reference/components/reference-form';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import PivotTableWrapper from '../../pivot-table/components/pivot-table-wrapper';
import { makeSelectAllAvailableCloAndCloInvoiceReferenceTypes } from '../../reference/selectors';
// forms
import SelectPrintTemplateForm from '../../../forms/forms/select-print-template-form';
import { SelectGLPostedDateForm } from '../../../forms/forms/select-gl-posted-date-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withMassActionSubmit } from '../../../hocs/with-mass-action-submit';
import { withFixedPopover, withSetExpandedContainerOptions } from '../../../hocs';
import { withAsyncEditCloInvoice } from '../../../hocs/with-async-edit-clo-invoice';
// report-common
import { reportEnhancer } from '../../../report-common';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  IconWrapper,
  ZOrderWrapper,
} from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature invoice
import StatusForm from '../components/status-form';
import ActionForm from '../components/action-form';
import { tableSettings } from '../settings/table-settings';
import { DETAILS_CELL, INVOICE_STATUS_FORM } from '../constants';
import { getStatusFormField } from '../settings/fields-settings';
import { getRenderModal, getChangeInvoicesTitle, getGuidsFromStringOrSelectedList } from '../helpers';
// feature invoice/customer
import { FILTER_PARAMS } from './settings/filter-params';
import { getColumnSettings } from './settings/column-settings';
import PrintAndSendEmails from './components/print-and-send-emails';
import {
  makeSelectConfigs,
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectShownMarkAsReady,
  makeSelectAvailableReports,
  makeSelectAdditionalFilters,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  selectItem,
  setReports,
  printRequest,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setAdditionalFilters,
  printByReportRequest,
  changeInvoicesRequest,
  getXMLByReportRequest,
  readyForBillingRequest,
  createReferenceRequest,
  resetListAndPagination,
  sendToFinancialRequest,
  printInvoiceListRequest,
  exportReportDataRequest,
  updateCloInvoiceRequest,
  getOrderInvoiceXMLRequest,
  changeDefaultReportRequest,
} from './actions';
//////////////////////////////////////////////////

const selectInvoiceLocaleTxt = G.getWindowLocale(
  'messages:driver:select-invoice',
  'Please, select an Invoice!',
);

const deleteConfirmationLocaleTxt = G.getWindowLocale(
  'messages:delete-confirmation-text-double',
  'Are you sure you want to delete',
);

const setElementActions = (props: Object, invoice: Object) => {
  const {
    closeFixedPopup,
    handlePrintInvoice,
    getItemListRequest,
    resetListAndPagination,
    getOrderInvoiceXMLRequest,
    handleExportInvoicesToEDI,
    handleSendInvoicesToFinancial,
    handleSendCustomerInvoiceListToBC,
    createQBIIFImportByInvoiceTypeRequest,
    handleGLPostedDateBeforSendToSageIntacct,
  } = props;

  const iconColor = G.getTheme('colors.dark.blue');
  const guid = G.getGuidFromObject(invoice);

  return [
    {
      permissions: [PC.CLO_WRITE],
      frontIcon: I.routesLoads(iconColor),
      text: G.getWindowLocale('actions:go-to-clo', 'Go to CLO'),
      action: () => G.goToLoadDetailsByConfigAndLoadType(routesMap, R.prop(GC.FIELD_CLO_GUID, invoice), true),
    },
    {
      frontIcon: I.printer(iconColor),
      action: () => {
        handlePrintInvoice(invoice);
        closeFixedPopup();
      },
      text: G.getWindowLocale('actions:print-invoice', 'Print Invoice'),
      permissions: [
        PC.CLO_INVOICE_READ,
        PC.CLO_INVOICE_WRITE,
        PC.CLO_INVOICE_OVERWRITE_EXECUTE,
      ],
    },
    {
      frontIcon: I.downloadDocument(iconColor, 16, 16),
      permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
      text: G.getWindowLocale('actions:get-order-invoice-xml-file', 'Get Order Invoice XML File'),
      action: () => {
        closeFixedPopup();
        getOrderInvoiceXMLRequest(guid);
      },
    },
    {
      frontIcon: I.quickbook(iconColor, 16, 16),
      permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
      text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
      action: () => {
        handleSendInvoicesToFinancial(guid);
        closeFixedPopup();
      },
    },
    {
      frontIcon: I.quickbook(iconColor, 16, 16),
      permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
      text: G.getWindowLocale('actions:export-to-QB-desktop', 'Export to QuickBooks Desktop'),
      action: () => {
        handleSendInvoicesToFinancial(guid, true);
        closeFixedPopup();
      },
    },
    {
      frontIcon: I.quickbook(iconColor, 16, 16),
      text: G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export'),
      action: () => {
        const callback = () => {
          resetListAndPagination();
          getItemListRequest(true);
        };
        closeFixedPopup();
        createQBIIFImportByInvoiceTypeRequest({
          callback,
          type: 'customer',
          guids: R.of(Array, guid),
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        });
      },
      permissions: [
        PC.CLO_INVOICE_READ,
        PC.CLO_INVOICE_WRITE,
        PC.CLO_INVOICE_OVERWRITE_EXECUTE,
      ],
    },
    {
      frontIcon: I.gear(iconColor),
      action: () => handleSendCustomerInvoiceListToBC(guid),
      permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
      text: G.getWindowLocale('actions:send-to-business-central', 'Send to Business Central'),
    },
    {
      frontIcon: I.gear(iconColor),
      action: () => handleGLPostedDateBeforSendToSageIntacct(guid),
      permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
      text: G.getWindowLocale('actions:send-to-sage-intacct', 'Send to Sage Intacct'),
    },
    {
      frontIcon: I.gear(iconColor),
      action: () => handleExportInvoicesToEDI(guid),
      text: G.getWindowLocale('actions:export-to-edi', 'Export to EDI'),
      permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
    },
  ];
};

const enhance = compose(
  withTabs2,
  withFixedPopover,
  reportEnhancer,
  withMassActionSubmit,
  withAsyncEditCloInvoice({ updateFrom: GC.PAGE_CUSTOMER_INVOICE_REPORT }),
  withSetExpandedContainerOptions,
  withHandlers({
    handlePrintAndSendEmails: (props: Object) => () => {
      const {
        itemList,
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        documentTemplates,
        printInvoiceListRequest,
      } = props;

      const invoiceGuids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      if (R.isEmpty(invoiceGuids)) {
        const message = G.getWindowLocale('messages:items:select-item', 'Please, select an Item!');

        return G.showToastrMessage('info', message);
      }

      const component = (
        <PrintAndSendEmails
          guids={invoiceGuids}
          openModal={openModal}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          submitAction={printInvoiceListRequest}
          configsNamesArray={[GC.CARRIER_DOCUMENT_TYPE]}
          documentTemplateOptions={G.mapNameGuidToLabelValue(
            R.pathOr([], [GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE], documentTemplates),
          )}
        />
      );

      const modal = {
        p: '0px',
        component,
        options: {
          minWidth: 800,
          maxWidth: '95vw',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:print-options', 'Print Options'),
        },
      };

      openModal(modal);
    },
    handleSendCustomerInvoiceListToBC: (props: Object) => (invoiceGuid: string) => {
      const {
        itemList,
        filterParams,
        selectedReport,
        closeFixedPopup,
        additionalFilters,
        handleMassActionSubmit,
        sendInvoiceToBCByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      if (G.isString(invoiceGuid)) {
        return sendInvoiceToBCByInvoiceTypeRequest({
          guids: R.of(Array, invoiceGuid),
          invoiceType: 'customerInvoice',
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        });
      }

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      const noSelected = R.isEmpty(guids);

      const submitAction = (data: Object) => {
        const { guids } = data;

        let dataToUse = {
          ...data,
          invoiceType: 'customerInvoice',
        };

        if (G.isNilOrEmpty(guids)) {
          const reqBody = {
            ...dataToUse,
            ...additionalFilters,
            searchCriteria: G.getOrElse(selectedReport, 'searchCriteria', []),
          };

          dataToUse = G.setSearchCriteria({ reqBody, filterParams });
        }

        sendInvoiceToBCByInvoiceTypeRequest(dataToUse);
      };

      handleMassActionSubmit({
        noSelected,
        submitAction,
        formValues: { guids },
      });
    },
    handleSendCustomerInvoiceListToSageIntacct: (props: Object) => (invoiceGuid: string, glPostedDate: any = null) => {
      const {
        itemList,
        filterParams,
        selectedReport,
        closeFixedPopup,
        additionalFilters,
        handleMassActionSubmit,
        sendInvoiceToSageByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      if (G.isString(invoiceGuid)) {
        return sendInvoiceToSageByInvoiceTypeRequest({
          guids: R.of(Array, invoiceGuid),
          invoiceType: 'customerInvoice',
          [GC.FIELD_GL_POSTED_DATE]: glPostedDate,
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        });
      }

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      const noSelected = R.isEmpty(guids);

      const submitAction = (data: Object) => {
        const { guids } = data;

        let dataToUse = {
          ...data,
          invoiceType: 'customerInvoice',
          [GC.FIELD_GL_POSTED_DATE]: glPostedDate,
        };

        if (G.isNilOrEmpty(guids)) {
          const reqBody = {
            ...dataToUse,
            ...additionalFilters,
            searchCriteria: G.getOrElse(selectedReport, 'searchCriteria', []),
          };

          dataToUse = G.setSearchCriteria({ reqBody, filterParams });
        }

        sendInvoiceToSageByInvoiceTypeRequest(dataToUse);
      };

      handleMassActionSubmit({
        noSelected,
        submitAction,
        formValues: { guids },
      });
    },
    handleExportInvoicesToEDI: (props: Object) => (invoiceGuid: string) => {
      const {
        itemList,
        closeModal,
        filterParams,
        selectedReport,
        closeFixedPopup,
        additionalFilters,
        handleMassActionSubmit,
        exportInvoicesToEDIRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      if (G.isString(invoiceGuid)) {
        return exportInvoicesToEDIRequest({ guids: R.of(Array, invoiceGuid) });
      }

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      const noSelected = R.isEmpty(guids);

      const submitAction = (data: Object) => {
        const { guids } = data;

        let dataToUse = data;

        if (G.isNilOrEmpty(guids)) {
          const reqBody = {
            ...dataToUse,
            ...additionalFilters,
            searchCriteria: G.getOrElse(selectedReport, 'searchCriteria', []),
          };

          dataToUse = G.setSearchCriteria({ reqBody, filterParams });
        }

        closeModal();
        exportInvoicesToEDIRequest(dataToUse);
      };

      handleMassActionSubmit({
        noSelected,
        submitAction,
        formValues: { guids },
      });
    },
  }),
  withHandlers({
    handleGLPostedDateBeforSendToSageIntacct: (props: Object) => (invoiceGuid: string) => {
      const {
        itemList,
        openModal,
        closeModal,
        handleSendCustomerInvoiceListToSageIntacct,
      } = props;

      const guids = G.getGuidsFromStringOrSelectedList(invoiceGuid, itemList);

      if (R.propEq(0, 'length', guids)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:driver:select-invoice', 'Please, select an Invoice!'),
        );
      }

      const component = (
        <SelectGLPostedDateForm
          closeModal={closeModal}
          submitAction={(date: any) => handleSendCustomerInvoiceListToSageIntacct(invoiceGuid, date)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          height: 'auto',
          footerZIndex: 'unset',
          title: G.getWindowLocale('titles:gl-posted-date', 'GL Posted Date'),
        },
      };

      openModal(modal);
    },
    handleDeleteInvoice: (props: Object) => (guid: string) => {
      const {
        openModal,
        closeModal,
        deleteItemRequest,
      } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        text: deleteConfirmationLocaleTxt,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: G.getWindowLocale('titles:customer-invoices', 'Customer Invoice(s)'),
        submitAction: () => {
          deleteItemRequest({
            guids: R.of(Array, guid),
            currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
          });
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleOpenSubmitDeleteInvoices: (props: Object) => () => {
      const {
        itemList,
        openModal,
        closeModal,
        deleteItemRequest,
        handleMassActionSubmit,
      } = props;

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      const noSelected = R.isEmpty(guids);

      const submitMassAction = (data: Object) => {
        const { excludedGuids } = data;

        let name;

        const customerInvoicesText = G.getWindowLocale('titles:customer-invoices', 'Customer Invoice(s)');

        if (noSelected) {
          name = `${G.getWindowLocale('titles:all', 'All')} ${customerInvoicesText}`;
        } else if (G.isNotNilAndNotEmpty(excludedGuids)) {
          name = `${G.getWindowLocale('titles:all-except-selected', 'All Except Selected')} ${customerInvoicesText}`;
        } else {
          name = `${G.getWindowLocale('titles:selected', 'Selected')} ${customerInvoicesText}`;
        }

        const modalContent = getConfirmModal({
          name,
          cancelAction: closeModal,
          text: deleteConfirmationLocaleTxt,
          cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
          submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
          submitAction: () => {
            deleteItemRequest(data);
            closeModal();
          },
        });

        openModal(modalContent);
      };
      handleMassActionSubmit({
        noSelected,
        formValues: { guids },
        submitAction: (data: Object) => submitMassAction(data),
      });
    },
    handleChangeInvoices: (props: Object) => (invoiceGuid: string, invoice: Object) => {
      const {
        configs,
        itemList,
        openModal,
        closeModal,
        branchGuid,
        changeInvoicesRequest,
        handleMassActionSubmit,
      } = props;

      const invoiceGuids = getGuidsFromStringOrSelectedList(invoiceGuid, itemList);
      const title = getChangeInvoicesTitle(invoiceGuid);
      const noSelected = R.isEmpty(invoiceGuids);
      const submitMassAction = (data: Object) => changeInvoicesRequest(data);

      const submitAction = (values: Object) => handleMassActionSubmit({
        noSelected,
        formValues: values,
        submitAction: (data: Object) => submitMassAction(data),
      });

      let initialValues = {};

      if (G.isNotNilAndNotEmpty(invoice)) {
        initialValues = R.compose(
          R.assoc(GC.FIELD_STATUS, R.path([GC.FIELD_STATUS, GC.FIELD_CONFIG_GUID], invoice)),
          R.pick([
            GC.FIELD_CHECK_AMOUNT,
            GC.FIELD_INVOICE_NET_DAYS,
            GC.FIELD_INVOICE_CHECK_DATE,
            GC.FIELD_INVOICE_CHECK_NUMBER,
            GC.FIELD_INVOICE_DEPOSIT_DATE,
          ]),
        )(invoice);
      }

      const component = (
        <ActionForm
          configs={configs}
          branchGuid={branchGuid}
          closeModal={closeModal}
          invoiceGuids={invoiceGuids}
          submitAction={submitAction}
          initialValues={initialValues}
          statusConfigName='CIStatusConfig'
        />
      );

      const modal = G.getDefaultModalOptions(component, title);

      openModal(modal);
    },
    handleSendInvoicesToFinancial: (props: Object) => (invoiceGuid: string, desktop: boolean) => {
      const { itemList, handleMassActionSubmit, sendToFinancialRequest } = props;

      if (G.isString(invoiceGuid)) {
        return sendToFinancialRequest({
          desktop,
          guids: R.of(Array, invoiceGuid),
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        });
      }

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      const noSelected = R.isEmpty(guids);

      handleMassActionSubmit({
        noSelected,
        formValues: { guids, desktop },
        submitAction: sendToFinancialRequest,
      });
    },
    handlePrintInvoice: (props: Object) => (invoice: Object) => {
      const { openModal, closeModal, printRequest } = props;

      const branchGuid = R.prop(`${GC.FIELD_CLO}.${GC.BRANCH_GUID}`, invoice);

      const component = (
        <PrintForm
          closeModal={closeModal}
          branchGuid={branchGuid}
          useVendorDocuments={true}
          selectedEntity={G.getGuidFromObject(invoice)}
          cloGuid={G.getPropFromObject(GC.FIELD_CLO_GUID, invoice)}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE}
          submitAction={({ templateGuid, vendorDocumentGuids }: Object) => printRequest({
            templateGuid,
            format: 'pdf',
            vendorDocumentGuids,
            [GC.FIELD_GUID]: G.getGuidFromObject(invoice),
          })}
        />
      );

      const modal = G.getDefaultModalOptions(component, G.getWindowLocale('titles:print-invoice', 'Print Invoice'));

      openModal(modal);
    },
    handlePrintInvoicesByReport: ({ openModal, printByReportRequest }: Object) => () => {
      const component = (
        <SelectPrintTemplateForm
          submitAction={(values: Object) => printByReportRequest(values)}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE_REPORT}
        />
      );

      const modal = G.getDefaultModalOptions(component, G.getWindowLocale('titles:print-invoice', 'Print Invoice'));

      openModal(modal);
    },
    handleQBIIFImportList: (props: Object) => (invoiceGuid: string) => {
      const {
        itemList,
        filterParams,
        selectedReport,
        additionalFilters,
        handleMassActionSubmit,
        createQBIIFImportByInvoiceTypeRequest,
      } = props;

      if (G.isString(invoiceGuid)) {
        return createQBIIFImportByInvoiceTypeRequest({
          type: 'customer',
          guids: R.of(Array, invoiceGuid),
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        });
      }

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      const noSelected = R.isEmpty(guids);

      const submitAction = (data: Object) => {
        const { guids } = data;

        let dataToUse = {
          ...data,
          type: 'customer',
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        };

        if (G.isNilOrEmpty(guids)) {
          const reqBody = {
            ...dataToUse,
            ...additionalFilters,
            searchCriteria: G.getOrElse(selectedReport, 'searchCriteria', []),
          };

          dataToUse = G.setSearchCriteria({ reqBody, filterParams });
        }

        createQBIIFImportByInvoiceTypeRequest(dataToUse);
      };

      handleMassActionSubmit({
        noSelected,
        submitAction,
        formValues: { guids },
      });
    },
  }),
  withHandlers({
    getAdditionalFilteredListRequest: (props: Object) => (filters: Object) => {
      const {
        getItemListRequest,
        setAdditionalFilters,
        resetListAndPagination,
      } = props;

      resetListAndPagination();
      setAdditionalFilters(filters);
      getItemListRequest(true);
    },
    handleAddReferenceList: (props: Object) => () => {
      const { itemList, openModal, createReferenceRequest } = props;

      const isSelected = (item: Object) => item.selected;

      const selectedList = R.map(
        (item: Object) => item.guid,
        R.filter(isSelected, itemList),
      );

      if (R.isEmpty(selectedList)) {
        return G.showToastrMessageSimple('info', selectInvoiceLocaleTxt);
      }

      const multiAddingEntRef = (values: Object) => {
        const params = R.assoc('primaryObjectGuids', selectedList, values);
        createReferenceRequest(params);
      };

      const modalContent = (
        <ReferenceFormComponent
          scope={GC.REF_SCOPE_NAME_CUSTOMER_INVOICE}
          submitAction={(params: Object) => multiAddingEntRef(params)}
        />
      );

      const modal = G.createAddReferenceModalOptions(modalContent);

      openModal(modal);
    },
    handleClickEditIcon: (props: Object) => (e: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <ActionsElement
            entity={entity}
            closeFixedPopup={props.closeFixedPopup}
            options={setElementActions(props, entity)}
          />
        ),
      })
    ),
    handleOpenCloDetails: ({ handleSetExpandedContainerOptions }: Object) => (visitPageGuid: string) => {
      handleSetExpandedContainerOptions({
        visitPageGuid,
        openContainerAsNewWindow: true,
        componentType: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
      });
    },
    handleClickRFB: (props: Object) => (reqData: Object) => {
      const {
        openModal,
        closeModal,
        branchGuid,
        readyForBillingRequest,
      } = props;

      const { data, ready } = reqData;

      if (G.isFalse(ready)) return readyForBillingRequest(reqData);

      const title = G.getWindowLocale('titles:invoice-status', 'Invoice Status');
      let initialValues = {};

      if (G.isNotNilAndNotEmpty(data)) {
        initialValues = R.assoc('option', R.path([GC.FIELD_STATUS, GC.FIELD_CONFIG_GUID], data), data);
      }

      const component = (
        <StatusForm
          type='customer'
          branchGuid={branchGuid}
          closeModal={closeModal}
          optionsName='CIStatusConfig'
          initialValues={initialValues}
          submitAction={(values: Object) => readyForBillingRequest(R.assoc('values', values, reqData))}
        />
      );

      const modal = {
        p: 15,
        component,
        options: { title },
      };

      openModal(modal);
    },
  }),
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const modalContent = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          version: 2,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

export const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    selectedReport,
    titleSortValues,
    shownMarkAsReady,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleDeleteInvoice,
    handleEditCloInvoice,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const elementActionsComponent = (entity: Object) => (
    <AuthWrapper
      has={[
        PC.CLO_INVOICE_READ,
        PC.CLO_INVOICE_WRITE,
        PC.CLO_INVOICE_OVERWRITE_EXECUTE,
      ]}
    >
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, entity)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const allChecked = G.isAllChecked(itemList);

  const reportToUse = R.assoc(
    'fields',
    R.prepend(
      { name: DETAILS_CELL, sequence: 0, freezed: true },
      selectedReport.fields,
    ),
    selectedReport,
  );

  const editItem = (guid: string, invoice: Object) => {
    const cloBranchGuidFieldName = `${GC.FIELD_CLO}.${GC.BRANCH_GUID}`;
    const branchGuid = R.prop(cloBranchGuidFieldName, invoice);

    handleEditCloInvoice(R.assoc(GC.BRANCH_GUID, branchGuid, invoice));
  };

  const actionButtons = [
    {
      action: editItem,
      iconName: 'pencil',
      permissions: [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
    },
    {
      iconName: 'trash',
      action: handleDeleteInvoice,
      permissions: [PC.CLO_INVOICE_DELETE_EXECUTE],
    },
  ];

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    onOptionClick: selectItem,
    withResizableColumns: true,
    useSearchableColumns: true,
    useNewTitleFilterInputs: true,
    columnSettings: getColumnSettings(props),
    handleLoadMoreEntities: getItemListRequest,
    renderRightStickedComponent: elementActionsComponent,
    report: G.ifElse(G.isTrue(shownMarkAsReady), reportToUse, selectedReport),
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

const getTabs = (activeTab: number) => [
  {
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:customer-invoices', 'Customer Invoice(s)'),
  },
  {
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:pivot-table', 'Pivot Table'),
  },
];

const CustomerInvoicesComponent = (props: Object) => {
  const {
    itemList,
    activeTab,
    totalCount,
    startPivotTable,
    handleSetActiveTab,
    handleChangeInvoices,
    pivotTableTotalCount,
    handleQBIIFImportList,
    getXMLByReportRequest,
    handlePrintAndSendEmails,
    handlePrintInvoicesByReport,
    handleSendInvoicesToFinancial,
    getAdditionalFilteredListRequest,
  } = props;

  const isList = R.equals(activeTab, 0);

  const handleClickTab = (i: number) => {
    if (isList) startPivotTable(GC.PIVOT_CUSTOMER_INVOICE_REPORT);

    handleSetActiveTab(i);
  };

  const count = G.ifElse(isList, totalCount, pivotTableTotalCount);

  const customTitleComponent = (
    <Tabs2
      count={count}
      activeTab={activeTab}
      tabs={getTabs(activeTab)}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  if (R.not(isList)) {
    return <PivotTableWrapper {...props} pl={10} p='30px 15px 15px' tabs={customTitleComponent} />;
  }

  const mainLightColor = G.getTheme('colors.white');
  const permissions = [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE];

  const listActionsOpt = [
    {
      permissions,
      action: getXMLByReportRequest,
      icon: I.downloadDocument(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    {
      type: 'massAction',
      icon: I.printer(mainLightColor, 20, 20),
      action: () => handlePrintAndSendEmails(),
      text: G.getWindowLocale('actions:print-and-send-emails', 'Print And Send Emails'),
    },
    // TODO: uncomment after adding logic to back-end
    // {
    //   permissions,
    //   type: 'massAction',
    //   action: handleAddReferenceList,
    //   icon: I.plusRound(mainLightColor, 25, 25),
    //   text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
    // },
    {
      permissions,
      type: 'massAction',
      action: handleChangeInvoices,
      icon: I.pencil(mainLightColor, 20, 20),
      text: G.getWindowLocale('actions:change-invoices', 'Change Invoices'),
    },
    {
      permissions,
      type: 'massAction',
      action: handleSendInvoicesToFinancial,
      icon: I.quickbook('transparent', 25, 25),
      text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
    },
    {
      permissions,
      type: 'massAction',
      icon: I.quickbook('transparent', 25, 25),
      action: (guids: Array) => handleSendInvoicesToFinancial(guids, true),
      text: G.getWindowLocale('actions:export-to-QB-desktop', 'Export to QuickBooks Desktop'),
    },
    {
      type: 'massAction',
      action: handleQBIIFImportList,
      icon: I.quickbook('transparent', 25, 25),
      permissions: R.prepend(PC.CLO_INVOICE_READ, permissions),
      text: G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export'),
    },
    {
      type: 'massAction',
      icon: I.trash(mainLightColor, 20, 20),
      permissions: [PC.CLO_INVOICE_DELETE_EXECUTE],
      action: props.handleOpenSubmitDeleteInvoices,
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
    {
      permissions,
      type: 'massAction',
      icon: I.gear(mainLightColor, 25, 25),
      action: props.handleSendCustomerInvoiceListToBC,
      text: G.getWindowLocale('actions:send-to-business-central', 'Send to Business Central'),
    },
    {
      permissions,
      type: 'massAction',
      icon: I.gear(mainLightColor, 25, 25),
      action: props.handleGLPostedDateBeforSendToSageIntacct,
      text: G.getWindowLocale('actions:send-to-sage-intacct', 'Send To Sage Intacct'),
    },
    {
      permissions,
      type: 'massAction',
      icon: I.gear(mainLightColor, 25, 25),
      action: props.handleExportInvoicesToEDI,
      text: G.getWindowLocale('actions:export-to-edi', 'Export to EDI'),
    },
  ];

  return (
    <Box p={15}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          withCount={true}
          popperWithCount={true}
          filterProps={FILTER_PARAMS}
          withAdditionalFilters={true}
          hiddenRightFilterInfo={false}
          type={GC.CUSTOMER_INVOICE_REPORT}
          allowSetAdditionalFiltersImmediately={true}
          customTitleComponent={customTitleComponent}
          additionalFiltersApplyHandler={getAdditionalFilteredListRequest}
          title={G.getWindowLocale('titles:customer-invoices', 'Customer Invoice(s)')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={permissions}>
        <PageActions
          listActions={listActionsOpt}
          shadowColor={G.getTheme('createButton.shadowColor')}
          count={R.length(R.filter((item: Object) => item.selected, R.or(itemList, [])))}
          mainAction={{
            permissions,
            action: handlePrintInvoicesByReport,
            text: G.getWindowLocale('actions:print-by-report', 'Print By Report'),
          }}
        />
      </AuthWrapper>
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  configs: makeSelectConfigs(state),
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  titleSortValues: makeSelectTitleSortValues(state),
  shownMarkAsReady: makeSelectShownMarkAsReady(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  documentTemplates: makeSelectDocumentTemplates(state),
  additionalFilters: makeSelectAdditionalFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  pivotTableTotalCount: makeSelectPivotTableTotalCount(state),
  refList: makeSelectAllAvailableCloAndCloInvoiceReferenceTypes(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  selectItem,
  openLoader,
  closeLoader,
  printRequest,
  setUsedReport,
  startPivotTable,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  printByReportRequest,
  setAdditionalFilters,
  changeInvoicesRequest,
  getXMLByReportRequest,
  readyForBillingRequest,
  createReferenceRequest,
  resetListAndPagination,
  sendToFinancialRequest,
  printInvoiceListRequest,
  updateCloInvoiceRequest,
  exportReportDataRequest,
  getOrderInvoiceXMLRequest,
  exportInvoicesToEDIRequest,
  changeDefaultReportRequest,
  sendInvoiceToBCByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
  createQBIIFImportByInvoiceTypeRequest,
})(enhance(CustomerInvoicesComponent));
