import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { EditReport } from '../../components';
import { Tabs2, withTabs2 } from '../../components/tabs-mui';
import { openModal, closeModal } from '../../components/modal/actions';
import { setReportTableOrder, updateTableOrderByGuid } from '../../common/idb/order/actions';
// features
import { loginLoadBoard123, loginLoadBoardUber } from '../oauth-popup/actions';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { makeSelectLoadBoardConfigList } from '../configurations/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// ui
import {
  Box,
  Flex,
  StickedFlex,
  ListWrapper,
  CancelButton,
  MainActionButton,
} from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature load-board
import {
  makeSelectTemplates,
  makeSelectUsedReport,
  makeSelectDataLoading,
  makeSelectShipmentList,
  makeSelectSearchFilters,
  makeSelectCancelledShown,
  makeSelectSavedShipments,
  makeSelectBookedShipments,
  makeSelectAvailableReports,
  makeSelectCompanyFilterList,
  makeSelectAvailableLoadBoards,
  makeSelectAuthorizedLoadBoards,
  makeSelectInitialPageDataLoaded,
} from './selectors';
import {
  setReports,
  setUsedReport,
  createReportRequest,
  updateReportRequest,
  saveTemplateRequest,
  toggleCancelledShown,
  loginLoadBoardRequest,
  logoutLoadBoardRequest,
  createSearchFilterRequest,
  changeDefaultReportRequest,
} from './actions';
import ResultList from './components/result-list';
import FiltersBlock from './components/filters-block';
import HeaderActions from './components/header-actions';
import SearchFilterForm from './components/search-filter-form';
import SaveTemplateForm from './components/save-template-form';
import LoadBoardsConfig from './components/load-boards-config';
import { LoginForm, LogoutForm } from './components/auth-forms';
import { AlternativeReportButtons } from './components/report-buttons';
//////////////////////////////////////////////////

const enhance = compose(
  withTabs2,
  withFixedPopover,
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        usedReport,
        setUsedReport,
        requestPending,
        createReportRequest,
        updateReportRequest,
      } = props;

      const modalContent = (
        <EditReport
          fields={fields}
          usedReport={usedReport}
          setReport={setUsedReport}
          disableSortByFields={true}
          disableFilterByFields={true}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );
      const modal = {
        component: modalContent,
        options: {
          version: 2,
          minWidth: 600,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };
      openModal(modal);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), props.reportList);

      props.setUsedReport(selectedReport);
    },
    handleEditOrder: (props: Object) => (report: Object, fields: Array) => {
      const {
        openModal,
        closeModal,
        setReportTableOrder,
        updateTableOrderByGuid,
      } = props;

      const { guid, reportType } = report;

      const setReport = (report: Object) => {
        const fields = R.prop('fields', report);

        updateTableOrderByGuid({
          reportType,
          reportGuid: guid,
          orderTable: fields,
        });
      };

      const resetReport = () => {
        updateTableOrderByGuid({
          reportType,
          orderTable: [],
          reportGuid: guid,
        });

        closeModal();
      };

      const modalContent = (
        <EditReport
          fields={fields}
          usedReport={report}
          setReport={setReport}
          requestPending={false}
          resetReport={resetReport}
          disableSortByFields={true}
          disableFilterByFields={true}
          AlternativeReportButtons={AlternativeReportButtons}
        />
      );
      const modal = {
        component: modalContent,
        options: {
          version: 2,
          minWidth: 600,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };
      openModal(modal);
    },
    handleGoToFullView: ({ closeExpandedContainer }: Object) => () => {
      closeExpandedContainer();

      G.goToRoute(routesMap.externalloadboard);
    },
    handleSaveTemplate: (props: Object) => (data: Object) => {
      const {
        openModal,
        closeModal,
        saveTemplateRequest,
      } = props;

      const omitFields = [
        GC.FIELD_LOAD_BOARD_DATE_LATEST,
        GC.FIELD_LOAD_BOARD_DATE_EARLIEST,
      ];

      const modal = {
        p: 15,
        component: (
          <SaveTemplateForm
            closeModal={closeModal}
            submit={(values: Object) => saveTemplateRequest(R.omit(omitFields, R.mergeRight(data, values)))}
          />
        ),
        options: {
          width: 330,
          height: 'auto',
          maxHeight: '95vh',
        },
      };

      openModal(modal);
    },
    handleLogin: ({
      openModal,
      closeModal,
      loginLoadBoard123,
      loginLoadBoardUber,
      loginLoadBoardRequest,
    }: Object) => (type: string) => {
      const modal = {
        p: 15,
        component: (
          <LoginForm
            closeModal={closeModal}
            initialValues={{ type }}
            fieldsGroupWidth='min-content'
            submit={(data: Object) => {
              const { type } = data;

              if (R.equals(type, GC.EXTERNAL_LOAD_BOARD_UBER)) {
                loginLoadBoardUber(data);
              } else if (R.equals(type, GC.EXTERNAL_LOAD_BOARD_LB123)) {
                loginLoadBoard123(data);
              } else {
                loginLoadBoardRequest(data);
              }
            }}
          />
        ),
        options: {
          width: 330,
          height: 'auto',
          maxHeight: '95vh',
          title: G.getWindowLocale('titles:external-loadboard-login', 'Login External Load Board'),
        },
      };

      openModal(modal);
    },
    handleLogout: (props: Object) => (type: string) => {
      const {
        openModal,
        closeModal,
        logoutLoadBoardRequest,
      } = props;

      const modal = {
        p: 15,
        component: (
          <LogoutForm
            closeModal={closeModal}
            initialValues={{ type }}
            fieldsGroupWidth='min-content'
            submit={(data: Object) => logoutLoadBoardRequest(data)}
          />
        ),
        options: {
          width: 330,
          height: 'auto',
          maxHeight: '95vh',
          title: G.getWindowLocale('titles:logout', 'Logout'),
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleCreateSearchFilter: (props: Object) => () => {
      const {
        templates,
        openModal,
        closeModal,
        handleSaveTemplate,
        availableLoadBoards,
        createSearchFilterRequest,
      } = props;

      const templateOptions = R.map(
        ({ name, guid }: Object) => ({ label: name, value: guid }),
        templates,
      );

      const loadBoardOptions = R.filter(
        ({ value }: Object) => G.isNotNil(R.find(
          R.propEq(value, GC.FIELD_TYPE),
          availableLoadBoards,
        )),
        GC.EXTERNAL_LOAD_BOARD_LIST_OPTIONS,
      );

      const modal = {
        fixedWidth: true,
        isExpandedContainer: true,
        wrapperStyles: { width: 490 },
        title: G.getWindowLocale('actions:post-equipment', 'Post Equipment'),
        component: (
          <SearchFilterForm
            cancelAction={closeModal}
            saveTemplate={handleSaveTemplate}
            templateOptions={templateOptions}
            loadBoardOptions={loadBoardOptions}
            templates={G.indexByGuid(templates)}
            handleSave={createSearchFilterRequest}
          />
        ),
        options: {
          minWidth: 490,
          minHeight: '100vh',
          enableResizing: false,
          default: { x: 0, y: 0, width: 490, height: '100vh' },
          style: { backgroundColor: G.getTheme('colors.white') },
        },
      };

      openModal(modal);
    },
    handleConfigureLoadBoards: (props: Object) => () => {
      const {
        openModal,
        handleLogin,
        logoutLoadBoardRequest,
      } = props;

      const modal = {
        p: '0',
        component: (
          <LoadBoardsConfig
            handleLogin={handleLogin}
            handleLogout={logoutLoadBoardRequest}
          />
        ),
        options: {
          width: 500,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '95vh',
          withCloseIcon: true,
          title: G.getWindowLocale('actions:configure-load-boards', 'Load Boards'),
        },
      };

      openModal(modal);
    },
  }),
  branch(
    (props: Object) => R.or(
      R.not(props.initialDataLoaded),
      R.not(props.initialPageDataLoaded),
    ),
    renderNothing,
  ),
  pure,
);

const getTabs = (activeTab: number) => [
  {
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:active-search', 'Active Search'),
  },
  {
    withCount: R.equals(activeTab, 1),
    text: G.getWindowLocale('titles:templates', 'Templates'),
  },
  {
    withCount: R.equals(activeTab, 2),
    text: G.getWindowLocale('titles:saved-shipments', 'Saved Shipments'),
  },
  {
    withCount: R.equals(activeTab, 3),
    text: G.getWindowLocale('titles:booked-shipments', 'Booked Shipments'),
  },
];

const buttonStyles = {
  mr: 20,
  height: 32,
  width: 150,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
  textColor: G.getTheme('colors.greyMatterhorn'),
};

const LoadBoard = enhance(({
  filters,
  loading,
  templates,
  activeTab,
  openModal,
  shipments,
  reportList,
  setReports,
  usedReport,
  setActiveTab,
  cancelledShown,
  companyFilters,
  savedShipments,
  bookedShipments,
  handleEditOrder,
  handleEditReport,
  expandedContainer,
  handleGoToFullView,
  handleSelectReport,
  handleSaveTemplate,
  availableLoadBoards,
  authorizedLoadBoards,
  toggleCancelledShown,
  closeExpandedContainer,
  handleCreateSearchFilter,
  handleConfigureLoadBoards,
  changeDefaultReportRequest,
}: Object) => {
  const count = R.length(
    R.prop(
      activeTab,
      [
        R.or(filters, []),
        R.or(templates, []),
        R.or(savedShipments, []),
        R.or(bookedShipments, []),
      ],
  ));

  return (
    <Box data-testid='testid-LoadBoardComponent-wrapper'>
      <ListWrapper
        bgColor={G.getTheme('colors.bgGrey')}
        {...(expandedContainer && { pl: '8px' })}
        height={G.ifElse(G.isTrue(expandedContainer), 'calc(100vh - 65px)', '100vh')}
      >
        <Flex
          height='100%'
          alignItems='normal'
          flexDirection='column'
          bg={G.getTheme('colors.white')}
        >
          <Flex pr={15} justifyContent='space-between'>
            <Tabs2
              count={count}
              activeTab={activeTab}
              tabs={getTabs(activeTab)}
              setActiveTab={setActiveTab}
            />
            <HeaderActions
              availableLoadBoards={availableLoadBoards}
              authorizedLoadBoards={authorizedLoadBoards}
              filtersCount={R.propOr(0, 'length', filters)}
              handleCreateSearchFilter={handleCreateSearchFilter}
              handleConfigureLoadBoards={handleConfigureLoadBoards}
            />
          </Flex>
          <FiltersBlock
            activeTab={activeTab}
            templates={templates}
            savedShipments={savedShipments}
            bookedShipments={bookedShipments}
            handleEditOrder={handleEditOrder}
            handleSaveTemplate={handleSaveTemplate}
            availableLoadBoards={availableLoadBoards}
          />
          <ResultList
            loading={loading}
            report={usedReport}
            itemList={shipments}
            openModal={openModal}
            reportList={reportList}
            setReports={setReports}
            cancelledShown={cancelledShown}
            companyFilters={companyFilters}
            handleEditReport={handleEditReport}
            handleSelectReport={handleSelectReport}
            toggleCancelledShown={toggleCancelledShown}
            changeDefaultReportRequest={changeDefaultReportRequest}
          />
        </Flex>
      </ListWrapper>
      {
        G.isTrue(expandedContainer) &&
        <StickedFlex
          p={15}
          bottom='0px'
          boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
          background={G.getTheme('colors.white')}
        >
          <CancelButton
            {...buttonStyles}
            onClick={() => closeExpandedContainer(true)}
            borderColor={G.getTheme('colors.greyMatterhorn')}
            data-testid='testid-LoadBoardComponent-expanded-cancel'
          >
            {G.getWindowLocale('actions:close', 'Close')}
          </CancelButton>
          <MainActionButton {...buttonStyles} onClick={handleGoToFullView}>
            {G.getWindowLocale('titles:go-to-full-view', 'Go To Full View')}
          </MainActionButton>
        </StickedFlex>
      }
    </Box>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  loading: makeSelectDataLoading(state),
  templates: makeSelectTemplates(state),
  filters: makeSelectSearchFilters(state),
  usedReport: makeSelectUsedReport(state),
  shipments: makeSelectShipmentList(state),
  reportList: makeSelectAvailableReports(state),
  cancelledShown: makeSelectCancelledShown(state),
  savedShipments: makeSelectSavedShipments(state),
  bookedShipments: makeSelectBookedShipments(state),
  companyFilters: makeSelectCompanyFilterList(state),
  availableLoadBoards: makeSelectAvailableLoadBoards(state),
  configuredLoadBoards: makeSelectLoadBoardConfigList(state),
  authorizedLoadBoards: makeSelectAuthorizedLoadBoards(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  initialPageDataLoaded: makeSelectInitialPageDataLoaded(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  setUsedReport,
  loginLoadBoard123,
  loginLoadBoardUber,
  createReportRequest,
  saveTemplateRequest,
  updateReportRequest,
  setReportTableOrder,
  toggleCancelledShown,
  loginLoadBoardRequest,
  logoutLoadBoardRequest,
  updateTableOrderByGuid,
  createSearchFilterRequest,
  changeDefaultReportRequest,
})(LoadBoard);
