import React from 'react';
import * as R from 'ramda';
import { useWindowSize } from 'react-use';
// feature master-invoice
import Orders from './orders';
import PaymentInfo from './payment-info';
import MasterInvoiceCharges from './master-invoice-charges';
//////////////////////////////////////////////////

const ActiveTab = (props: Object) => {
  const {
    activeTab,
    widthToUse,
    collapsedView,
  } = props;

  const { height } = useWindowSize();

  const maxHeight = collapsedView ? R.subtract(height, 550) : R.subtract(height, 220);

  if (R.equals(activeTab, 0)) {
    return <MasterInvoiceCharges {...props} width={widthToUse} minWidth={widthToUse} maxHeight={maxHeight} />;
  } else if (R.equals(activeTab, 1)) {
    return <PaymentInfo {...props} />;
  }

  return <Orders {...props} maxHeight={maxHeight} />;
};

export default ActiveTab;
