import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../common/selectors';
// components
import { Table } from '../../../components/table';
import { getConfirmModal } from '../../../components/confirm';
import { LocalLoader } from '../../../components/local-loader';
import { openModal, closeModal } from '../../../components/modal/actions';
import { withShowDocuments } from '../../../components/async-documents/with-show-documents';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withHandleAudit, withMasterInvoiceActions } from '../hocs';
import {
  withAsyncConfigs,
  withFixedPopover,
  withAsyncInitialDataOnDidMount,
  withAsyncGetAvailableFactoringTypes,
} from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, IconWrapper, BoxWithChildDiv } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature master-invoice
import * as C from '../constants';
import UpdateInvoices from './update-invoices';
import PrintOptionsForm from './print-options-form';
import MasterInvoiceWithCharges from './master-invoice-with-charges';
import AsyncMasterInvoiceInvoices from './async-master-invoice-invoices';
import AsyncPrintInvoicesOptionsForm from './print-invoices-options-form';
import { makeSelectDocumentTypeOptions, makeSelectMasterInvoiceConfigs } from '../selectors';
import { report, columnSettings, tableSettingsAsyncMasterInvoice } from '../settings/table-settings';
import {
  qbIIFImportRequest,
  printInvoicesRequest,
  sendToQuickBookRequest,
  createFactoringDocument,
  sendToSageIntacctRequest,
  printMasterInvoiceRequest,
  getMasterInvoiceXMLRequest,
  updateMasterInvoiceInvoices,
  deleteMasterInvoicesRequest,
  getFactoringCompanyFileRequest,
  handleUpdateMasterInvoiceRequest,
  createAndExportFactoringDocument,
  exportCustomerMasterInvoiceToEDIRequest,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncConfigs,
  withFixedPopover,
  withAsyncInitialDataOnDidMount,
  withAsyncGetAvailableFactoringTypes(),
  withHandlers({
    handleOpenEditMasterInvoiceForm: (props: Object) => (data: Object) => {
      const {
        openModal,
        closeModal,
        branchGuid,
        openLoader,
        closeLoader,
        setLoading,
        getInitialDataRequest,
        getCloInvoicesRequest,
        handleUpdateMasterInvoiceRequest,
      } = props;

      const masterInvoiceGuid = G.getGuidFromObject(data);

      const endpoint = endpointsMap.getCustomerMasterInvoiceEndpoint(masterInvoiceGuid);

      const title = `${
        G.getWindowLocale('titles:edit-master-invoice', 'Edit Master Invoice')} - ${
        R.path([GC.FIELD_MASTER_INVOICE_BILL_TO, GC.FIELD_LOCATION_NAME], data)}`;

      const component = (
        <MasterInvoiceWithCharges
          editMode={true}
          titleText={title}
          openModal={openModal}
          branchGuid={branchGuid}
          openLoader={openLoader}
          closeModal={closeModal}
          asyncEndpoint={endpoint}
          closeLoader={closeLoader}
          handleSendMasterInvoice={(data: Object) => {
            setLoading();

            const callback = () => {
              getInitialDataRequest();
              getCloInvoicesRequest({
                [GC.FIELD_GUID]: R.path([GC.FIELD_CLO_GUID], props),
              });
            };

            handleUpdateMasterInvoiceRequest(R.assoc('callback', callback, data));
          }}
        />
      );

      const modal = {
        component,
        options: {
          width: 1100,
          height: 'auto',
          maxHeight: '95vh',
        },
        p: '0',
      };

      openModal(modal);
    },
    handleOpenUpdateMasterInvoiceInvoices: (props: Object) => (data: Object) => {
      const {
        openModal,
        closeModal,
        setLoading,
        asyncConfigs,
        getInitialDataRequest,
        getCloInvoicesRequest,
        updateMasterInvoiceInvoices,
      } = props;

      const initialFilterDays = G.getConfigValueFromStore(
        GC.INVOICE_CLO_MASTER_INVOICE_INVOICE_FILTER_DATE_RANGE_DAYS,
        asyncConfigs,
        30,
      );

      const component = (
        <UpdateInvoices
          data={data}
          asyncMethod='post'
          closeModal={closeModal}
          initialFilterDays={initialFilterDays}
          asyncEndpoint={endpointsMap.customerMasterInvoiceInvoicesForUpdate}
          updateMasterInvoiceInvoices={(data: Object) => {
            setLoading();

            const callback = () => {
              getInitialDataRequest();
              getCloInvoicesRequest({
                [GC.FIELD_GUID]: R.path([GC.FIELD_CLO_GUID], props),
              });
            };

            updateMasterInvoiceInvoices(R.assoc('callback', callback, data));
          }}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          minWidth: 900,
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '95vh',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
    handleOpenSubmitDeleteMasterInvoice: (props: Object) => ({ guid, masterInvoiceNumber }: Object) => {
      const {
        openModal,
        closeModal,
        getCloInvoicesRequest,
        deleteMasterInvoicesRequest,
      } = props;

      const modalContent = getConfirmModal({
        name: masterInvoiceNumber,
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
        submitAction: () => {
          const callback = () => {
            closeModal();
            getCloInvoicesRequest({
              [GC.FIELD_GUID]: R.path([GC.FIELD_CLO_GUID], props),
            });
          };

          deleteMasterInvoicesRequest({ callback, guids: R.of(Array, guid) });
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handlePrintMasterInvoice: (props: Object) => (data: Object) => {
      const {
        openModal,
        closeModal,
        asyncConfigs,
        configOptions,
        documentTemplates,
        printMasterInvoiceRequest,
      } = props;

      const masterInvoiceDocumentTemplates = G.getPropFromObject(
        GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE,
        documentTemplates,
      );

      if (G.isNilOrEmpty(masterInvoiceDocumentTemplates)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:no-configured-document-templates', 'There are no configured document templates'),
        );
      }

      const templateOptions = R.map(
        ({ guid, name }: Object) => ({ value: guid, label: name }),
        masterInvoiceDocumentTemplates,
      );

      const documentTypeOptions = configOptions.map(({ value, displayedValue }: Object) => ({
        value,
        label: displayedValue,
      }));

      const defaultDocumentTypeGuids = G.getConfigValueFromStore(
        GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_DOCUMENT_TYPES,
        asyncConfigs,
        [],
      );

      const initialValues = {
        [C.DOCUMENT_TYPES]: R.or(defaultDocumentTypeGuids, null),
      };

      const carrierDocumentTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
        asyncConfigs,
        GC.CARRIER_DOCUMENT_TYPE,
      );

      const component = (
        <PrintOptionsForm
          closeModal={closeModal}
          useVendorDocuments={true}
          initialValues={initialValues}
          templateOptions={templateOptions}
          documentTypeOptions={documentTypeOptions}
          vendorDocumentTypeOptions={carrierDocumentTypeOptions}
          submitAction={(values: Object) => (
            printMasterInvoiceRequest(R.assoc(GC.FIELD_INVOICE_MASTER_INVOICE_GUID, G.getGuidFromObject(data), values))
          )}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 330,
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '95vh',
          title: G.getWindowLocale('titles:print-options', 'Print Options'),
        },
      };

      openModal(modal);
    },
    handlePrintInvoices: (props: Object) => ({ guid }: Object) => {
      const {
        configs,
        openModal,
        closeModal,
        branchGuid,
        configOptions,
        openFixedPopup,
        closeFixedPopup,
        documentTemplates,
        printInvoicesRequest,
        createFactoringDocument,
        createAndExportFactoringDocument,
      } = props;

      const cloDocumentTemplates = G.getPropFromObject(
        GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE,
        documentTemplates,
      );

      if (G.isNilOrEmpty(cloDocumentTemplates)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:no-configured-document-templates', 'There are no configured document templates'),
        );
      }

      const templateOptions = R.map(({ guid, name }: Object) => ({ value: guid, label: name }), cloDocumentTemplates);

      const documentTypeOptions = configOptions.map(({ value, displayedValue }: Object) => ({
        value,
        label: displayedValue,
      }));

      const initialValues = {
        [C.DOCUMENT_TYPES]: R.pathOr(null, ['defaultDocumentTypeGuids'], configs),
      };

      const options = {
        params: {
          [GC.FIELD_INVOICE_MASTER_INVOICE_GUID]: guid,
          documentTypeGuids: R.join(',', R.pathOr([], ['defaultDocumentTypeGuids'], configs)),
        },
      };

      const component = (
        <AsyncPrintInvoicesOptionsForm
          openModal={openModal}
          asyncOptions={options}
          closeModal={closeModal}
          branchGuid={branchGuid}
          masterInvoiceGuid={guid}
          initialValues={initialValues}
          openFixedPopup={openFixedPopup}
          closeFixedPopup={closeFixedPopup}
          documentTemplates={documentTemplates}
          documentTypeOptions={documentTypeOptions}
          asyncEndpoint={endpointsMap.customerMasterInvoiceInvoices}
          templateOptions={G.addEmptyOptionToDropDown(templateOptions)}
          activeFactoringIntegration={R.path(['availableFactoringTypes', GC.FIELD_FACTORING_TYPE], props)}
          saveAndSend={(values: Object) => (
            createAndExportFactoringDocument({
              ...values,
              [GC.FIELD_INVOICE_MASTER_INVOICE_GUID]: guid,
            })
          )}
          submitAction={(values: Object) => {
            const requestPayload = {
              ...values,
              [GC.FIELD_INVOICE_MASTER_INVOICE_GUID]: guid,
            };

            if (G.isNotNilAndNotEmpty(values[C.INTEGRATION_TYPE])) {
              return createFactoringDocument(requestPayload);
            }

            printInvoicesRequest(requestPayload);
          }}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          minWidth: 800,
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '95vh',
          title: G.getWindowLocale('titles:print-options', 'Print Options'),
        },
      };

      openModal(modal);
    },
  }),
  withShowDocuments,
  withHandleAudit,
  withMasterInvoiceActions,
  pure,
);

export const AsyncMasterInvoice = (props: Object) => {
  const {
    closeModal,
    asyncInitialData,
    masterInvoiceGuid,
    handleClickEditIcon,
  } = props;

  const { data, loading } = asyncInitialData;

  const asyncInvoicesOptions = {
    params: { masterInvoiceGuid },
  };

  const elementActionsComponent = (data: Object) => (
    <AuthWrapper has={[PC.CLO_MASTER_INVOICE_READ, PC.CLO_MASTER_INVOICE_WRITE]}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  return (
    <LocalLoader localLoaderOpen={loading}>
      <Flex flexDirection='column' alignItems='flex-start' width='100%'>
        <Box width='100%' overflow='auto'>
          <Table
            report={report}
            columnSettings={columnSettings}
            tableSettings={tableSettingsAsyncMasterInvoice}
            renderRightStickedComponent={elementActionsComponent}
            itemList={R.of(Array, R.assoc('closeModal', closeModal, data))}
          />
        </Box>
        <BoxWithChildDiv width='100%' childWidth='100%'>
          <AsyncMasterInvoiceInvoices
            closeModal={closeModal}
            asyncOptions={asyncInvoicesOptions}
            asyncEndpoint={endpointsMap.customerMasterInvoiceInvoices}
          />
        </BoxWithChildDiv>
      </Flex>
    </LocalLoader>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  configs: makeSelectMasterInvoiceConfigs(state),
  configOptions: makeSelectDocumentTypeOptions(state),
  documentTemplates: makeSelectDocumentTemplates(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  qbIIFImportRequest,
  printInvoicesRequest,
  sendToQuickBookRequest,
  createFactoringDocument,
  sendToSageIntacctRequest,
  printMasterInvoiceRequest,
  getMasterInvoiceXMLRequest,
  deleteMasterInvoicesRequest,
  updateMasterInvoiceInvoices,
  getFactoringCompanyFileRequest,
  handleUpdateMasterInvoiceRequest,
  createAndExportFactoringDocument,
  exportCustomerMasterInvoiceToEDIRequest,
})(enhance(AsyncMasterInvoice));
