// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const GROUP_BY_ID = 'ID';
export const GROUP_BY_NAME = 'NAME';
export const SEND_EMAILS = 'sendEmails';
export const MERGE_FILES = 'mergeFiles';
export const GROUP_BY_BRANCH = 'BRANCH';
export const GROUP_BY_NO_GROUP = 'NO_GROUP';
export const GROUP_BY_REFERENCE = 'REFERENCE';
export const DOCUMENT_TEMPLATE = 'templateGuid';
export const DOCUMENT_TYPES = 'documentTypeGuids';
export const INTEGRATION_TYPE_COMPASS = 'COMPASS';
export const INTEGRATION_TYPE_TRIUMPH = 'TRIUMPH';
export const INTEGRATION_TYPE = 'integrationType';
export const INTEGRATION_TYPE_E_CAPITAL = 'E_CAPITAL';
export const ADD_MASTER_INVOICE = 'ADD_MASTER_INVOICE';
export const ACCESSORIALS_OPTIONS = 'accessorialsOptions';
export const INTEGRATION_TYPE_OTR_CAPITAL = 'OTR_CAPITAL';
export const INTEGRATION_TYPE_RTS_FINANCIAL = 'RTS_FINANCIAL';
export const VENDOR_DOCUMENT_TYPES = 'vendorDocumentTypeGuids';
export const INTEGRATION_TYPE_RTS_FINANCIAL_MANUAL = 'RTS_FINANCIAL_MANUAL';

export const integrationTypeLocale = {
  [INTEGRATION_TYPE_COMPASS]: G.getWindowLocale('titles:compass', 'Compass'),
  [INTEGRATION_TYPE_TRIUMPH]: G.getWindowLocale('titles:triumph', 'Triumph'),
  [INTEGRATION_TYPE_E_CAPITAL]: G.getWindowLocale('titles:e-capital', 'eCapital'),
  [INTEGRATION_TYPE_OTR_CAPITAL]: G.getWindowLocale('titles:otr-capital', 'OTR Capital'),
  [INTEGRATION_TYPE_RTS_FINANCIAL]: G.getWindowLocale('titles:rts-financial', 'RTS Financial'),
  [INTEGRATION_TYPE_RTS_FINANCIAL_MANUAL]: G.getWindowLocale('titles:rts-financial-manual', 'RTS Financial Manual'),
};

export const INTEGRATION_TYPE_OPTIONS = [
  { label: integrationTypeLocale[INTEGRATION_TYPE_E_CAPITAL], value: INTEGRATION_TYPE_E_CAPITAL },
  { label: integrationTypeLocale[INTEGRATION_TYPE_COMPASS], value: INTEGRATION_TYPE_COMPASS },
  { label: integrationTypeLocale[INTEGRATION_TYPE_TRIUMPH], value: INTEGRATION_TYPE_TRIUMPH },
  { label: integrationTypeLocale[INTEGRATION_TYPE_OTR_CAPITAL], value: INTEGRATION_TYPE_OTR_CAPITAL },
  { label: integrationTypeLocale[INTEGRATION_TYPE_RTS_FINANCIAL], value: INTEGRATION_TYPE_RTS_FINANCIAL },
  { label: integrationTypeLocale[INTEGRATION_TYPE_RTS_FINANCIAL_MANUAL], value: INTEGRATION_TYPE_RTS_FINANCIAL_MANUAL },
];

export const UI_REPORT_NAME_MASTER_INVOICE_CHARGES = 'masterInvoiceCharges';
// report fields
export const FIELD_GROUP_NAME = 'groupName';
export const FIELD_GROUP_BY_REFERENCE_VALUE = 'groupByReferenceValue';

// document exportInfo (factoring)
export const FIELD_SENT_BY = 'sentBy';
export const FIELD_SENT_DATE = 'sentDate';
export const FIELD_INTEGRATION_TYPE = 'integrationType';
export const OBJECT_DOCUMENT_EXPORT_INFO = 'exportInfo';
export const FIELD_EXPORTED_DOCUMENT_GUID = 'exportedDocumentGuid';

export const masterInvoiceConfigDefaultGroupByOptions = [
  {
    value: GROUP_BY_NO_GROUP,
    label: G.getWindowLocale('titles:no-group', 'No Group'),
  },
  {
    value: GROUP_BY_NAME,
    label: G.getWindowLocale('titles:bill-to-location-name', 'Bill To Location Name'),
  },
  {
    value: GROUP_BY_BRANCH,
    label: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    value: GROUP_BY_REFERENCE,
    label: G.getWindowLocale('titles:reference', 'Reference'),
  },
];
