import * as R from 'ramda';
import React, { useMemo } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import useEditViewHook from '../../../hooks/use-edit-view';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import withChargeComments from '../../../hocs/with-charge-comments';
// ui
import { Box, Flex, MainActionButton } from '../../../ui';
// feature master-invoice
import PC from '../../permission/role-permission';
import { MasterInvoiceChargeForm } from './master-invoice-charge-form';
import {
  report,
  tableSettings,
  getSortedReport,
  getColumnSettings,
  defaultReportFields,
} from '../settings/master-invoice-with-charges-table-settings';
//////////////////////////////////////////////////

const chargesArrayName = GC.FIELD_MASTER_INVOICE_CHARGES;

const getChargeIndexAndCurrentCharges = (props: Object, charge: Object) => {
  const { values } = props;

  const currentCharges = R.pathOr([], [chargesArrayName], values);

  const chargeIdOrGuid = G.getIdOrGuidFromObject(charge);

  const index = R.findIndex(
    (item: Object) => R.or(
      R.propEq(chargeIdOrGuid, GC.FIELD_ID, item),
      R.propEq(chargeIdOrGuid, GC.FIELD_GUID, item),
    ),
    currentCharges,
  );

  return { index, currentCharges };
};

const enhanceCharge = compose(
  withHandlers({
    handleAddOrEditMasterInvoiceCharge: (props: Object) => ({ charge }: Object) => {
      const {
        type,
        values,
        openModal,
        closeModal,
        authorities,
        asyncConfigs,
        setFieldValue,
        glCodeMappings,
        accessorialsConfigs,
      } = props;

      const isEditMode = G.isNotNilAndNotEmpty(charge);
      const glDisabled = G.notContain(PC.GL_CODE_WRITE, authorities);
      const initialValues = G.ifElse(isEditMode, charge, {});

      const submitAction = (newCharge: Object) => {
        closeModal();

        const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

        if (isEditMode) {
          return setFieldValue(GC.FIELD_CHARGES, R.update(index, newCharge, currentCharges));
        }

        setFieldValue(GC.FIELD_CHARGES, R.prepend(R.assoc(GC.FIELD_ID, G.genShortId(), newCharge), currentCharges));
      };

      const component = (
        <MasterInvoiceChargeForm
          type={type}
          glDisabled={glDisabled}
          submitAction={submitAction}
          asyncConfigs={asyncConfigs}
          initialValues={initialValues}
          glCodeMappings={glCodeMappings}
          accessorialsConfigs={accessorialsConfigs}
          vendorGuid={G.getPropFromObject(GC.FIELD_FLEET_VENDOR_GUID, values)}
          shouldGenerateId={G.isNotNilAndNotEmpty(G.getGuidFromObject(charge))}
          vendorBranchGuid={R.path([GC.SYSTEM_OBJECT_FLEET_VENDOR, GC.BRANCH_GUID], values)}
        />
      );

      const getTitle = isEditMode ? G.getEditTitle : G.getAddTitle;

      const modal = {
        p: 15,
        component,
        options: {
          width: 760,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: getTitle(['titles:charge', 'Charge']),
        },
      };

      openModal(modal);
    },
    handleRemoveCharge: (props: Object) => (guid: string, charge: Object) => {
      const { setFieldValue } = props;

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      const updatedItems = R.remove(index, 1, currentCharges);

      setFieldValue(chargesArrayName, updatedItems);
    },
    handleUpdateChargeComments: (props: Object) => (comments: any, charge: Object) => {
      const { setFieldValue } = props;

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      const updatedItems = R.update(index, { ...charge, comments }, currentCharges);

      setFieldValue(chargesArrayName, updatedItems);
    },
  }),
  withChargeComments(),
  pure,
);

const getOptionsFromAccessorials = R.map((item: Object) => ({
  value: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
  label: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
}));

const Header = ({
  editViewBtnComponent,
  handleAddOrEditMasterInvoiceCharge,
}: Object) => (
  <Flex m='15px 0px 10px 15px'>
    <MainActionButton
      mr={15}
      height={30}
      width={120}
      type='button'
      onClick={() => handleAddOrEditMasterInvoiceCharge({})}
    >
      {G.getWindowLocale('titles:add-new-charge', 'Add New Charge')}
    </MainActionButton>
    { editViewBtnComponent }
  </Flex>
);

const MasterInvoiceCharges = (props: Object) => {
  const {
    width,
    maxHeight,
    asyncConfigs,
    handleRemoveCharge,
    accessorialsConfigs,
    handleUpdateChargeComments,
    handleAddOrEditMasterInvoiceCharge,
  } = props;

  const pathToCharges = ['values', chargesArrayName];
  const charges = R.pathOr([], pathToCharges, props);

  const chargeCurrency = G.getCurrencySymbol(GC.DEFAULT_UI_CURRENCY);

  const accessorialsOptions = useMemo(() =>
    getOptionsFromAccessorials(R.or(accessorialsConfigs, [])),
    [accessorialsConfigs]);

  const glCodeOptions = useMemo(() => G.addEmptyOptionToDropDown(
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.INVOICE_GL_CODE,
    ),
    G.getWindowLocale('titles:gl-code', 'GL Code'),
  ), [asyncConfigs]);

  const columnSettings = useMemo(() =>
    getColumnSettings(glCodeOptions, accessorialsOptions),
    [glCodeOptions, accessorialsOptions]);

  const {
    uiReportFields,
    handleOpenEditView,
    editViewBtnComponent,
  } = useEditViewHook({
    ...props,
    report,
    columnSettings,
    defaultReportFields,
  });

  const sortedReport = useMemo(() => (
    G.isNilOrEmpty(uiReportFields) ?
    report : getSortedReport(uiReportFields)
  ), [uiReportFields]);

  const actionButtons = [
    {
      iconName: 'pencil',
      text: G.getWindowLocale('titles:edit', 'Edit'),
      action: (_: any, charge: Object) => handleAddOrEditMasterInvoiceCharge({ charge }),
    },
    {
      iconName: 'trash',
      action: handleRemoveCharge,
      text: G.getWindowLocale('titles:delete', 'Delete'),
    },
  ];

  return (
    <Box
      px={15}
      width={width}
      borderTop='1px solid'
      borderColor={G.getTheme('colors.bgLightGrey')}
    >
      <Header {...props} editViewBtnComponent={editViewBtnComponent} />
      <Box p='5px'>
        <Table
          itemList={charges}
          showEditView={true}
          report={sortedReport}
          shouldUseUIFilters={true}
          useSearchableColumns={true}
          withResizableColumns={true}
          actionButtons={actionButtons}
          useNewTitleFilterInputs={true}
          columnSettings={columnSettings}
          handleClickEditOrder={handleOpenEditView}
          tableSettings={R.assoc('maxHeight', maxHeight, tableSettings)}
          filterProps={G.getFilterPropsFromColumnSettings(columnSettings)}
          callbackData={{ asyncConfigs, chargeCurrency, handleUpdateChargeComments }}
        />
      </Box>
    </Box>
  );
};

export default enhanceCharge(MasterInvoiceCharges);
