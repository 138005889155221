import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { OuterClick } from 'react-outer-click';
import { pure, compose, withHandlers } from 'react-recompose';
// feature new-do
import SectionDivider from '../../new-do/components/section-divider';
import { FORM_BOX_SHADOW, TAB_NAME_PRICING } from '../../new-do/constants';
import { isValidReferencesForm, validationSchemaReferencesForm } from '../../new-do/validation';
// forms
import { Fieldset2 } from '../../../forms/formik';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box } from '../../../ui';
// feature lite-new-do
import FormTab from './form-tab';
import PrevNextAction from './validate-action';
import ReferencesArray from './references-array';
import { noteFields, specialInstructionsRefFields } from '../settings';
//////////////////////////////////////////////////

const ReferenceForm = (props: Object) => {
  const {
    submitForm,
    handleClickPrev,
    handleOuterClick,
  } = props;

  return (
    <OuterClick onOuterClick={handleOuterClick}>
      <form>
        <FormTab {...props} />
        <Box boxShadow={FORM_BOX_SHADOW}>
          <Box pt={20}>
            <SectionDivider
              mt='0px'
              text={G.getWindowLocale(
                'titles:special-instructions',
                'Special Instructions',
                { caseAction: 'upperCase' })}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              errorTop={85}
              fieldsWrapperStyles={{ px: 10 }}
              fields={specialInstructionsRefFields}
            />
            <SectionDivider
              text={G.getWindowLocale(
                'titles:note',
                'Note',
                { caseAction: 'upperCase' })}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              errorTop={85}
              fields={noteFields}
              fieldsWrapperStyles={{ px: 10 }}
            />
            <ReferencesArray {...props} />
            <PrevNextAction
              showNext={true}
              submitForm={submitForm}
              handleClickPrev={handleClickPrev}
            />
          </Box>
        </Box>
      </form>
    </OuterClick>
  );
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaReferencesForm,
    mapPropsToValues: ({ initialValues }: Object) => initialValues,
    handleSubmit: (values: Object, { props }: Object) => {
      const { setActiveLeftTab, setFormDataToStore } = props;

      setFormDataToStore({ dataName: 'referenceFormData', formData: R.assoc('isValid', true, values) });

      setActiveLeftTab(TAB_NAME_PRICING);
    },
    displayName: 'ReferenceForm',
  }),
  withHandlers({
    onClickTabCallback: (props: Object) => (activeName: Object) => {
      const { values, setActiveLeftTab, setFormDataToStore } = props;

      const isValid = isValidReferencesForm(values);

      setFormDataToStore({ dataName: 'referenceFormData', formData: R.assoc('isValid', isValid, values) });

      setActiveLeftTab(activeName);
    },
    handleClickPrev: (props: Object) => () => {
      const { stops, values, setActiveLeftTab, setFormDataToStore } = props;

      const isValid = isValidReferencesForm(values);

      setFormDataToStore({ dataName: 'referenceFormData', formData: R.assoc('isValid', isValid, values) });

      setActiveLeftTab(R.length(R.values(stops)));
    },
    handleOuterClick: (props: Object) => () => {
      const { values, setFormDataToStore } = props;

      const isValid = isValidReferencesForm(values);

      setFormDataToStore({ dataName: 'referenceFormData', formData: R.assoc('isValid', isValid, values) });
    },
  }),
  pure,
);

export default enhance(ReferenceForm);
