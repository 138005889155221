import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { ExpandedDetails } from '../../dispatch-report/components/table-fields';
// utilities
import routesMap from '../../../utilities/routes';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const report = {
  type: GC.MASTER_INVOICE_UI_REPORTS,
  guid: `${GC.MASTER_INVOICE_UI_REPORTS}OrdersReport`,
  fields: [
    { sequence: 1, name: GC.FIELD_PRIMARY_REFERENCE_VALUE },
    { sequence: 2, name: GC.FIELD_INVOICE_TOTAL },
    { sequence: 3, name: GC.FIELD_RATE_TOTAL },
    { sequence: 4, name: GC.FIELD_STATUS },
    { sequence: 5, name: GC.FIELD_FIRST_EVENT_COMPLETE_DATE },
    { sequence: 6, name: GC.FIELD_LAST_EVENT_COMPLETE_DATE },
    { sequence: 7, name: GC.FIELD_TRUCK_UNIT_IDS, collection: true },
    { sequence: 8, name: GC.FIELD_TRAILER_UNIT_IDS, collection: true },
  ],
};

const defaultReportFields = R.compose(
  R.pluck(GC.FIELD_NAME),
  R.prop('fields'),
)(report);

const getSortedReport = (fieldNames: Array) => ({
  ...report,
  fields: fieldNames.map((name: string, sequence: number) => ({ name, sequence })),
});

const columnSettings = {
  orderDetails: {
    name: '',
    width: 35,
    searchable: false,
    notResizable: true,
    customComponent: ({ data, callbackData }: Object) =>
      <ExpandedDetails {...data} {...callbackData} loadType={GC.LOAD_TYPE_CLO} />,
  },
  loadDetails: {
    name: '',
    width: 35,
    searchable: false,
    notResizable: true,
    customComponent: ({ data }: Object) => <ExpandedDetails {...data} loadType={GC.LOAD_TYPE_TEL} />,
  },
  [GC.FIELD_PRIMARY_REFERENCE_VALUE]: {
    width: 250,
    searchable: true,
    name: 'titles:primary-ref-value',
    customComponent: ({ data, fieldData }: Object) => (
      <TextComponent
        display='block'
        maxWidth='100%'
        cursor='pointer'
        withEllipsis={true}
        color={G.getTheme('colors.light.blue')}
        title={G.getWindowLocale('actions:go-to-details', 'Need locale')}
        onClick={() => G.goToRoute(routesMap.dispatchDetailsOrder(R.prop(GC.FIELD_GUID, data)))}
      >
        {fieldData}
      </TextComponent>
    ),
  },
  [GC.FIELD_INVOICE_TOTAL]: {
    width: 250,
    searchable: true,
    pivotType: 'number',
    filter: { type: 'number' },
    name: 'titles:invoice-total',
  },
  [GC.FIELD_RATE_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:total-rate',
    filter: { type: 'number' },
    customComponent: ({ data }: Object) => {
      const isClo = R.has(GC.GRC.TELS_GUID, data);

      const permissions = G.ifElse(
        isClo,
        [PC.CLO_RATE_READ, PC.CLO_RATE_WRITE],
        [PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE],
      );

      return (
        <AuthWrapper has={permissions}>{R.prop(GC.FIELD_RATE_TOTAL, data)}</AuthWrapper>
      );
    },
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    type: 'enum',
    name: ['titles:route', 'titles:status'],
  },
  [GC.FIELD_FIRST_EVENT_COMPLETE_DATE]: {
    searchable: true,
    filter: { type: 'date' },
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:complete-date'],
  },
  [GC.FIELD_LAST_EVENT_COMPLETE_DATE]: {
    searchable: true,
    filter: { type: 'date' },
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:complete-date'],
  },
  [GC.FIELD_TRUCK_UNIT_IDS]: {
    width: 200,
    searchable: true,
    name: ['titles:truck', 'titles:unit-id'],
    filter: {
      type: 'string',
      collection: true,
    },
  },
  [GC.FIELD_TRAILER_UNIT_IDS]: {
    width: 200,
    searchable: true,
    name: 'titles:trailers',
    filter: {
      type: 'string',
      collection: true,
    },
  },
};

export {
  report,
  columnSettings,
  getSortedReport,
  defaultReportFields,
};
