import * as R from 'ramda';
import React, { useMemo, useEffect } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components';
import useEditViewHook from '../../../hooks/use-edit-view';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex, StickedBox } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature master-invoice
import * as H from '../helpers';
import InvoiceDetails from './invoice-details';
import {
  report,
  columnSettings,
  getSortedReport,
  defaultReportFields,
} from '../settings/master-invoice-orders-settings';
//////////////////////////////////////////////////

const OrderInvoices = (props: Object) => {
  const {
    closeModal,
    parentProps,
    editInvoice,
    addDocument,
    removeInvoice,
    documentTypes,
    openFixedPopup,
    closeFixedPopup,
    masterInvoiceGuid,
  } = props;

  return (
    <StickedBox
      left='0px'
      width='100vw'
      display='inline-block'
      bg={G.getTheme('colors.white')}
    >
      <Flex
        p={15}
        width='100%'
        flexWrap='wrap'
        border='1px solid'
        alignItems='baseline'
        borderColor={G.getTheme('tables.rows.borderColor')}
      >
        {
          parentProps.orderInvoices.map((invoice: Object, i: number) => (
            <InvoiceDetails
              {...invoice}
              notesHidden={true}
              closeModal={closeModal}
              documentTypes={documentTypes}
              openFixedPopup={openFixedPopup}
              closeFixedPopup={closeFixedPopup}
              edit={() => editInvoice(invoice)}
              key={`master-invoice-invoice-${invoice.guid}-${i}`}
              addDocument={() => addDocument(invoice, masterInvoiceGuid)}
              actionsDisabled={R.pathOr(false, ['actionsDisabled'], props)}
              remove={(invoiceGuid: string, invoiceNumber: string) => removeInvoice(
                invoiceGuid,
                invoiceNumber,
                masterInvoiceGuid,
                parentProps.guid,
              )}
            />
          ))
        }
      </Flex>
    </StickedBox>
  );
};

const getTableSettings = ({
  closeModal,
  addDocument,
  editInvoice,
  removeInvoice,
  documentTypes,
  openFixedPopup,
  closeFixedPopup,
  handleGetOrders,
  masterInvoiceGuid,
  handleUpdateInvoiceSuccess,
  handleRemoveInvoiceSuccess,
}: Object) => ({
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 12,
  allowEditBtn: true,
  tableRowHeight: 40,
  titleRowHeight: 45,
  expandableItems: true,
  checkBoxCellWidth: 30,
  searchableTitles: true,
  allowSelectItems: false,
  tableWrapperProps: {
    maxHeight: 'calc(100vh - 540px)',
  },
  expandedDetailsComponent: (props: Object) => (
    <OrderInvoices
      {...props}
      closeModal={closeModal}
      documentTypes={documentTypes}
      openFixedPopup={openFixedPopup}
      closeFixedPopup={closeFixedPopup}
      masterInvoiceGuid={masterInvoiceGuid}
      editInvoice={(invoice: Object) => editInvoice(invoice, handleUpdateInvoiceSuccess)}
      addDocument={(invoice: Object, masterInvoiceGuid: string) => (
        addDocument(invoice, masterInvoiceGuid, handleGetOrders)
      )}
      removeInvoice={(
        invoiceGuid: string,
        invoiceNumber: string,
        masterInvoiceGuid: string,
        cloGuid: string,
      ) => removeInvoice(
        invoiceGuid,
        invoiceNumber,
        masterInvoiceGuid,
        ({ invoiceGuid }: Object) => handleRemoveInvoiceSuccess({ cloGuid, invoiceGuid }),
      )}
    />
  ),
});

const enhance = compose(
  withHandlers({
    handleGetOrders: (props: Object) => async () => {
      const { orders, setOrders, masterInvoiceGuid, documentTypeGuids } = props;

      try {
        const res = await sendRequest('get', endpointsMap.cloListForMasterInvoice, { params: { masterInvoiceGuid } });

        const { data, status } = res;

        if (G.isResponseSuccess(status)) {
          const params = {
            masterInvoiceGuid,
            [GC.FIELD_DOCUMENT_TYPE_GUIDS]: R.join(',', R.or(documentTypeGuids, [])),
          };

          const invoicesRes = await sendRequest('get', endpointsMap.customerMasterInvoiceInvoices, { params });

          const isInvoicesSuccess = G.isResponseSuccess(invoicesRes.status);

          const newOrders = G.mapIndexed(
            (order: Object, i: number) => ({
              ...order,
              expanded: R.pathOr(false, [i, 'expanded'], orders),
              orderInvoices: isInvoicesSuccess ?
              R.filter(
                R.propEq(G.getGuidFromObject(order), GC.FIELD_CLO_GUID),
                R.or(invoicesRes.data, []),
              ) :
                [],
            }),
            data,
          );

          setOrders(newOrders);
        }
      } catch (error) {
        G.handleException('error', 'handleGetOrders exception');
      }
    },
    handleToggleOrder: (props: Object) => ({ guid }: Object) => {
      const { orders, setOrders } = props;

      setOrders(R.map(
        (order: Object) => {
          if (R.propEq(guid, GC.FIELD_GUID, order)) {
            const { expanded } = order;

            return R.assoc('expanded', R.not(expanded), order);
          }

          return order;
        },
        orders,
      ));
    },
    handleUpdateInvoiceSuccess: (props: Object) => (data: Object) => {
      const { orders, values, setOrders, setFieldValue } = props;

      const { guid, cloGuid } = data;

      const { version } = values;

      const mappedInvoice = G.mapDropdownsObjectInEntity(
        GC.FIELD_DISPLAYED_VALUE,
        [GC.FIELD_INVOICE_STATUS, GC.FIELD_MODE],
        data,
      );

      const newOrders = R.map(
        (order: Object) => {
          if (R.propEq(cloGuid, GC.FIELD_GUID, order)) {
            const orderInvoices = R.map(
              (invoice: Object) => {
                if (R.propEq(guid, GC.FIELD_GUID, invoice)) {
                  return R.mergeRight(
                    mappedInvoice,
                    {
                      documents: invoice.documents,
                      cloPrimaryReferenceValue: invoice.cloPrimaryReferenceValue,
                      ...H.getInvoiceChargesFromResponse(mappedInvoice.charges),
                    },
                  );
                }

                return invoice;
              },
              order.orderInvoices,
            );

            return { ...order, orderInvoices };
          }

          return order;
        },
        orders,
      );

      setOrders(newOrders);
      setFieldValue(GC.FIELD_VERSION, R.inc(version));
    },
    handleRemoveInvoiceSuccess: (props: Object) => ({ cloGuid, invoiceGuid }: Object) => {
      const { orders, values, setOrders, setFieldValue } = props;

      const { version } = values;

      const newOrders = R.map(
        (order: Object) => {
          if (R.propEq(cloGuid, GC.FIELD_GUID, order)) {
            return R.assoc(
              'orderInvoices',
              R.filter(
                ({ guid }: Object) => G.notEquals(guid, invoiceGuid),
                order.orderInvoices,
              ),
              order,
            );
          }

          return order;
        },
        orders,
      );

      setOrders(newOrders);

      setFieldValue(GC.FIELD_VERSION, R.inc(version));
    },
  }),
  pure,
);

const Orders = (props: Object) => {
  const {
    orders,
    handleGetOrders,
    handleToggleOrder,
    getInitialDataRequest,
    masterInvoiceDataUniqId,
  } = props;

  useEffect(() => {
    handleGetOrders();
  }, [masterInvoiceDataUniqId]);

  const freezedField = {
    sequence: 0,
    freezed: true,
    name: 'orderDetails',
  };

  const {
    uiReportFields,
    handleOpenEditView,
  } = useEditViewHook({
    ...props,
    report,
    columnSettings,
    defaultReportFields,
  });

  const sortedReport = useMemo(() => (
    G.isNilOrEmpty(uiReportFields) ?
    report : getSortedReport(uiReportFields)
  ), [uiReportFields]);

  const data = {
    columnSettings,
    itemList: orders,
    showEditView: true,
    hasSelectable: true,
    loading: R.isNil(orders),
    shouldUseUIFilters: true,
    toggle: handleToggleOrder,
    useSearchableColumns: true,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    tableSettings: getTableSettings(props),
    handleClickEditOrder: handleOpenEditView,
    filterProps: G.getFilterPropsFromColumnSettings(columnSettings),
    report: G.prependFieldToTableReportFields(sortedReport, freezedField),
    callbackData: {
      expandedContainerAdditionalOptions: { activeTab: 'invoicing', callback: getInitialDataRequest },
    },
  };

  return <Table {...data} />;
};

export default enhance(Orders);
